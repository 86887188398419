import { ITimeRange } from '@/types/common.type'
import { IImport, IImportTask } from '@/types/import.type'
import { ISchedule, ITrip, ScheduleStatus } from '@/types/trips.type'
import { format } from 'date-fns'
import { api } from './axios.interceptor'

class TripsService {
  async getByTime(request: ITimeRange) {
    const response = await api.get<ITrip[]>('/trips/byTime', {
      params: request
    })

    response.data = response.data.map(trip => {
      const updatedSchedules = trip.schedules.map(schedule => {
        schedule.status = schedule.status as ScheduleStatus
        return schedule
      })

      trip.schedules = updatedSchedules
      return trip
    })

    return response
  }

  async getSchedulesByTime(request: ITimeRange) {
    const response = await api.get<ISchedule[]>('/trips/schedule/byTime', {
      params: request
    })

    return response
  }

  async getById(id: number) {
    const response = await api.get<ITrip>(`/trips/${id}`)

    return response
  }

  async create(request: Omit<ITrip, 'id'>) {
    const response = await api.post<ITrip>('/trips', request)
    return response
  }

  async getSignature(id: number, signatureType: 'rider' | 'driver') {
    const response = await api.get<string>(
      `/trips/schedule/${id}/signature/${signatureType}`
    )

    return response
  }

  async update(id: number, schedule: ITrip) {
    const response = await api.patch<ITrip>(`/trips/${id}`, schedule)
    return response
  }

  async setScheduleStatus(
    id: number,
    request: {
      forAllRiderTrips?: boolean
      status: ScheduleStatus
    }
  ) {
    const response = await api.patch<ISchedule>(
      `/trips/schedule/${id}/setStatus`,
      request
    )
    return response
  }

  async updateSchedule(id: number, schedule: Partial<ISchedule>) {
    const response = await api.patch<ITrip>(`/trips/schedule/${id}`, schedule)
    return response
  }

  async uncombine(tripId: number) {
    const response = await api.patch<ITrip[]>(`/trips/${tripId}/uncombine`)
    return response
  }

  async assign(id: number, shiftId: number) {
    const response = await api.patch<ITrip>(`/trips/${id}/assign`, {
      shiftId: shiftId
    })
    return response
  }

  async unassign(id: number, uncombine?: boolean) {
    const response = await api.patch<ITrip>(`/trips/${id}/unassign`, {
      uncombine: uncombine
    })
    return response
  }

  async startImport(request: IImport) {
    const response = await api.post<string>(`/trips/import`, request, {
      timeout: 120000
    })
    return response
  }

  async getImportStats(taskId: string) {
    const response = await api.get<IImportTask>(`/trips/import/${taskId}`)
    return response
  }

  async autoschedule(date: Date) {
    const response = await api.post<boolean>(
      `/trips/autoschedule/${format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'")}`,
      {},
      {
        timeout: 45000
      }
    )

    return response
  }

  async calledSchedule(id: number, date: Date | string) {
    const response = await api.patch<ITrip>(`/trips/schedule/${id}/called`, {
      date: date
    })
    return response
  }
}

const tripsService = new TripsService()

export default tripsService
