import { IconType } from 'react-icons'
import {
  TbAdjustments,
  TbAnchor,
  TbBell,
  TbBolt,
  TbBrush,
  TbCalendar,
  TbCalendarPlus,
  TbClock,
  TbDashboard,
  TbDeviceLaptop,
  TbFileImport,
  TbGraph,
  TbHome,
  TbMail,
  TbMap,
  TbMoon,
  TbPassword,
  TbPlus,
  TbRefresh,
  TbReport,
  TbSettings,
  TbShirt,
  TbSun,
  TbTable,
  TbTruck,
  TbUser,
  TbUserDollar,
  TbUserPlus,
  TbUserShield,
  TbWand
} from 'react-icons/tb'
import { Link, RouteObject } from 'react-router-dom'
import Layout from './Layout'
import AdminForm from './pages/AdminsPage/AdminForm'
import AdminsPage from './pages/AdminsPage/AdminsPage'
import BrokerForm from './pages/BrokersPage/BrokerForm'
import BrokersPage from './pages/BrokersPage/BrokersPage'
import SettingsPage from './pages/CompanySettingsPage/CompanySettingsPage'
import DashboardPage from './pages/DashboardPage/DashboardPage'
import DispatchPage from './pages/DispatchPage/DispatchPage'
import DriversPage from './pages/DriversPage/DriversPage'
import DriverForm from './pages/DriversPage/form/DriverForm'
import EmailChangePage from './pages/EmailChangePage/EmailChangePage'
import ErrorPage from './pages/ErrorPage'
import LiveMapPage from './pages/LiveMapPage/LiveMapPage'
import LoginPage from './pages/LoginPage/LoginPage'
import MeSettingsPage from './pages/MeSettingsPage/MeSettingsPage'
import PasswordResetPage from './pages/PasswordRecoveryPage/PasswordRecoveryPage'
import ReportsPage from './pages/ReportsPage/ReportsPage'
import RiderForm from './pages/RidersPage/RiderForm'
import RidersPage from './pages/RidersPage/RidersPage'
import ShiftForm from './pages/ShiftsPage/ShiftForm'
import ShiftMultipleForm from './pages/ShiftsPage/ShiftMultipleForm'
import ShiftsPage from './pages/ShiftsPage/ShiftsPage'
import SignupPage from './pages/SignupPage/SignupPage'
import TripForm from './pages/TripsPage/TripForm'
import TripsPage from './pages/TripsPage/TripsPage'
import VehicleForm from './pages/VehiclesPage/VehicleForm'
import VehiclesPage from './pages/VehiclesPage/VehiclesPage'

export interface ISearchable {
  icon: IconType
  name: string
  path: string
}

export const routes: RouteObject[] = [
  {
    path: '/',
    handle: {
      crumb: () => <Link to='/'>Home</Link>
    },
    element: <Layout />,
    errorElement: (
      <ErrorPage
        title='Oops! Page not found.'
        description='The page you’re trying to access isn’t available or might have been moved. Don’t worry — you can head back to the homepage and try again.'
        href='/'
        button='Get back home'
      />
    ),
    children: [
      {
        path: '/',
        element: <DashboardPage />,
        handle: {
          search: [
            { icon: TbDashboard, name: 'Dashboard', path: '/' },
            { icon: TbGraph, name: 'Statistics', path: '/' },
            { icon: TbHome, name: 'Home', path: '/' }
          ]
        }
      },
      {
        path: '/reports',
        element: <ReportsPage />,
        handle: {
          crumb: () => <Link to='/reports'>Reports</Link>,
          search: [{ icon: TbReport, name: 'Reports', path: '/reports' }]
        }
      },
      {
        path: '/live-map',
        element: <LiveMapPage />,
        handle: {
          crumb: () => <Link to='/reports'>Live Map</Link>,
          search: [{ icon: TbMap, name: 'Live Map', path: '/live-map' }]
        }
      },
      {
        path: '/shifts',
        handle: {
          crumb: () => <Link to={`/shifts`}>Shifts</Link>
        },
        children: [
          {
            path: '',
            element: <ShiftsPage />,
            handle: {
              search: [{ icon: TbShirt, name: 'Shifts', path: '/shifts' }]
            }
          },
          {
            path: ':shiftId',
            handle: {
              crumb: (data: string) => (
                <Link to={`/shifts/${data}`}>{data}</Link>
              ),
              search: {
                icon: TbShirt,
                name: 'Create new Shift',
                path: '/shifts/create'
              }
            },
            element: <ShiftForm />
          },
          {
            path: 'recurring/create',
            handle: {
              crumb: () => <span>Create (recurring)</span>,
              search: {
                icon: TbShirt,
                name: 'Create reurring shifts',
                path: '/shifts/recurring/create'
              }
            },
            element: <ShiftMultipleForm />
          }
        ]
      },
      {
        path: '/dispatch',
        handle: {
          crumb: () => <Link to='/dispatch'>Dispatch</Link>,
          search: [
            { icon: TbClock, name: 'Dispatch', path: '/dispatch' },
            { icon: TbBolt, name: 'Today Trips', path: '/dispatch' },
            { icon: TbWand, name: 'Autoschedule', path: '/dispatch' }
          ]
        },
        element: <DispatchPage />
      },
      {
        path: '/trips',
        handle: {
          crumb: () => <Link to='/trips'>Trips</Link>,
          search: [
            { icon: TbCalendar, name: 'Trips', path: '/trips' },
            { icon: TbFileImport, name: 'Import Trips', path: '/trips?import' }
          ]
        },
        children: [
          {
            path: ':tripId',
            handle: {
              crumb: (data: string) => (
                <Link to={`/trips/${data}`}>{data}</Link>
              ),
              search: {
                icon: TbCalendarPlus,
                name: 'Create new Trip',
                path: '/trips/create'
              }
            },
            element: <TripForm />
          },
          {
            path: '',
            element: <TripsPage />
          }
        ]
      },
      {
        path: '/preferences',
        element: <MeSettingsPage />,
        handle: {
          crumb: () => <Link to='/preferences'>Preferences</Link>,
          search: [
            {
              icon: TbSettings,
              name: 'User Preferences',
              path: '/preferences'
            },
            {
              icon: TbUser,
              name: 'Account',
              path: '/preferences?tab=account'
            },
            {
              icon: TbUser,
              name: 'Change Username',
              path: '/preferences?tab=account'
            },
            {
              icon: TbPassword,
              name: 'Change Password',
              path: '/preferences?tab=account'
            },
            {
              icon: TbBell,
              name: 'Change Notifications',
              path: '/preferences?tab=notifications'
            },
            {
              icon: TbBell,
              name: 'Manage e-mails',
              path: '/preferences?tab=notifications'
            },
            {
              icon: TbBrush,
              name: 'Appearance',
              path: '/preferences?tab=appearance'
            },
            {
              icon: TbMoon,
              name: 'Dark Theme',
              path: '/preferences?tab=appearance'
            },
            {
              icon: TbSun,
              name: 'Light Theme',
              path: '/preferences?tab=appearance'
            },
            {
              icon: TbDeviceLaptop,
              name: 'System Theme',
              path: '/preferences?tab=appearance'
            }
          ]
        }
      },
      {
        path: '/settings',
        element: <SettingsPage />,
        handle: {
          crumb: () => <Link to='/settings'>Settings</Link>,
          search: [
            {
              icon: TbAdjustments,
              name: 'Company Settings',
              path: '/settings'
            },
            {
              icon: TbAdjustments,
              name: 'Autoscheduler Settings',
              path: '/settings?tab=autoscheduler'
            },
            {
              icon: TbTable,
              name: 'Presets',
              path: '/settings?tab=presets'
            },
            {
              icon: TbRefresh,
              name: 'Restore Presets',
              path: '/settings?tab=presets'
            }
          ]
        }
      },
      {
        path: '/drivers',
        handle: {
          crumb: () => <Link to='/drivers'>Drivers</Link>,
          search: { icon: TbUser, name: 'Drivers', path: '/drivers' }
        },
        children: [
          {
            path: ':driverId',
            handle: {
              crumb: (data: string) => (
                <Link to={`/drivers/${data}`}>{data}</Link>
              ),
              search: {
                icon: TbUserPlus,
                name: 'Invite new Driver',
                path: '/drivers?invite'
              }
            },
            element: <DriverForm />
          },
          {
            path: '',
            element: <DriversPage />
          }
        ]
      },
      {
        path: '/riders',
        handle: {
          crumb: () => <Link to='/riders'>Riders</Link>,
          search: { icon: TbAnchor, name: 'Riders', path: '/riders' }
        },
        children: [
          {
            path: ':riderId',
            handle: {
              crumb: (data: string) => (
                <Link to={`/riders/${data}`}>{data}</Link>
              ),
              search: {
                icon: TbPlus,
                name: 'Create new Rider',
                path: '/riders/create'
              }
            },
            element: <RiderForm />
          },
          {
            path: '',
            element: <RidersPage />
          }
        ]
      },
      {
        path: '/vehicles',
        handle: {
          crumb: () => <Link to='/vehicles'>Vehicles</Link>,
          search: { icon: TbTruck, name: 'Vehicles', path: '/vehicles' }
        },
        children: [
          {
            path: ':vehicleId',
            handle: {
              crumb: (data: string) => (
                <Link to={`/vehicles/${data}`}>{data}</Link>
              ),
              search: {
                icon: TbPlus,
                name: 'Create new Vehicle',
                path: '/vehicles/create'
              }
            },
            element: <VehicleForm />
          },
          {
            path: '',
            element: <VehiclesPage />
          }
        ]
      },
      {
        path: '/brokers',
        handle: {
          crumb: () => <Link to='/brokers'>Brokers</Link>,
          search: { icon: TbUserDollar, name: 'Brokers', path: '/brokers' }
        },
        children: [
          {
            path: ':brokerId',
            handle: {
              crumb: (data: string) => (
                <Link to={`/brokers/${data}`}>{data}</Link>
              ),
              search: {
                icon: TbUserPlus,
                name: 'Create new Broker',
                path: '/brokers/create'
              }
            },
            element: <BrokerForm />
          },
          {
            path: '',
            element: <BrokersPage />
          }
        ]
      },
      {
        path: '/admins',
        handle: {
          crumb: () => <Link to='/admins'>Admins</Link>,
          search: { icon: TbUserShield, name: 'Admins', path: '/admins' }
        },
        children: [
          {
            path: ':adminId',
            handle: {
              crumb: (data: string) => (
                <Link to={`/admins/${data}`}>{data}</Link>
              ),
              search: {
                icon: TbMail,
                name: 'Invite new Admin',
                path: '/admins?invite'
              }
            },
            element: <AdminForm />
          },
          {
            path: '',
            element: <AdminsPage />
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/password-reset',
    element: <PasswordResetPage />
  },
  {
    path: '/change-email',
    element: <EmailChangePage />
  },
  {
    path: '/signup',
    element: <SignupPage />
  }
]
