import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import Spinner from '@/components/ui/Spinner'
import { useVehicles } from '@/hooks/queries/useVehicles'
import { FC, useState } from 'react'
import { TbPlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import VehiclesTable from './VehiclesTable'

const VehiclesPage: FC = () => {
  const vehicles = useVehicles(false)
  const [makeFilter, setMakeFilter] = useState<string>('')

  const navigate = useNavigate()

  return (
    <div className='flex flex-col gap-4 w-full h-full'>
      <div className='flex flex-row gap-2 relative'>
        <Input
          value={makeFilter}
          onChange={e => setMakeFilter(e.target.value)}
          placeholder='Filter make...'
          className='w-40 h-8'
        />
        <Button
          onClick={() => {
            navigate(`/vehicles/create`)
          }}
          className='absolute h-8 right-0'
        >
          <TbPlus className='h-4 w-4 mr-2' />
          Create
        </Button>
      </div>
      {vehicles.data && !vehicles.isLoading ? (
        <VehiclesTable
          data={vehicles.data}
          columnFilters={[{ id: 'make', value: makeFilter }]}
        />
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default VehiclesPage
