import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import { useUnassignTrip } from '@/hooks/mutations/useUnassignTrip'
import { ITrip } from '@/types/trips.type'
import { FC, useEffect, useState } from 'react'

interface IUnassignDialogProps {
  unassignableTrip: ITrip | undefined
  setUnassignableTrip: (value: ITrip | undefined) => void
}

const UnassignDialog: FC<IUnassignDialogProps> = (
  props: IUnassignDialogProps
) => {
  const { mutate: unassignTrip } = useUnassignTrip()

  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!props.unassignableTrip) {
      return setOpen(false)
    }

    if (props.unassignableTrip.schedules.length === 1) {
      unassignTrip({
        id: props.unassignableTrip.id,
        uncombine: false
      })

      return props.setUnassignableTrip(undefined)
    }

    setOpen(true)
  }, [props.unassignableTrip])

  return (
    <Dialog
      open={open}
      onOpenChange={() => props.setUnassignableTrip(undefined)}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Unassign multiloaded trips</DialogTitle>
          <DialogDescription>
            These trips are multiloaded, do you want to only unassign these
            trips or uncombine them too?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                unassignTrip({
                  id: props.unassignableTrip?.id!,
                  uncombine: true
                })
              }}
              variant='outline'
            >
              Unassign and uncombine
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              onClick={() => {
                unassignTrip({
                  id: props.unassignableTrip?.id!,
                  uncombine: false
                })
              }}
            >
              Unassign only
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
export default UnassignDialog

