import { Button } from '@/components/ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import EnumInput from '@/components/ui/inputs/EnumInput'
import {
  createReportSchema,
  ICreateReport,
  ReportTheme
} from '@/types/reports.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChevronsUpDown } from 'lucide-react'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TbUser } from 'react-icons/tb'
import { useCreateRidersReport } from './hooks/useCreateRidersReport'

const RidersReportDialog: FC = () => {
  const { mutate: createReport } = useCreateRidersReport()

  const [open, setOpen] = useState<boolean>(false)

  const form = useForm<ICreateReport>({
    resolver: yupResolver(createReportSchema),
    defaultValues: {
      name: 'Riders Report',
      theme: ReportTheme.Light
    }
  })

  const handleSubmit = (data: ICreateReport) => {
    console.log(data)
    createReport(data)
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant='ghost'>
          <TbUser className='min-h-4 min-w-4 mr-2' />
          Riders Report
        </Button>
      </DialogTrigger>
      <Form {...form}>
        <DialogContent>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className='flex flex-col gap-4'
          >
            <DialogHeader>
              <DialogTitle>Generate Riders Report</DialogTitle>
              <DialogDescription>
                This action will generate a new Riders Report. Processing may
                take some time, and you can monitor the status in the Reports
                table. Once processing is complete, the report will be available
                for download
              </DialogDescription>
            </DialogHeader>
            <FormField
              control={form.control}
              name='name'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name *</FormLabel>
                  <FormControl>
                    <Input {...field} className='w-full h-8' />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Collapsible>
              <CollapsibleTrigger asChild>
                <Button variant='link' className='p-0'>
                  <ChevronsUpDown className='h-4 w-4 mr-1' />
                  Advanced options
                </Button>
              </CollapsibleTrigger>
              <CollapsibleContent className='ml-4'>
                <FormField
                  control={form.control}
                  name='theme'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Theme *</FormLabel>
                      <FormControl>
                        <EnumInput
                          {...field}
                          required
                          value={field.value}
                          enum={ReportTheme}
                          fieldClassName='w-full h-8'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CollapsibleContent>
            </Collapsible>
            <DialogFooter>
              <DialogClose asChild>
                <Button variant='outline'>Close</Button>
              </DialogClose>
              <Button type='submit'>Create</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Form>
    </Dialog>
  )
}
export default RidersReportDialog

