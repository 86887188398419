import { cn } from '@/lib/utils'
import { FC, HTMLAttributes } from 'react'

interface ILogoProps
  extends Omit<HTMLAttributes<HTMLImageElement>, 'src' | 'alt'> {}

const Logo: FC<ILogoProps> = (props: ILogoProps) => {
  return (
    <img
      {...props}
      alt='ManageNEMT Logo'
      className={cn('h-16 w-16', props.className)}
      src='/logo.svg'
    />
  )
}
export default Logo

