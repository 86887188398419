import { nameRegExp } from '@/lib/utils'
import { phoneTest, yupTransformer } from '@/lib/yup'
import { boolean, object, ObjectSchema, string } from 'yup'

export enum UserRole {
  Admin = 'Admin',
  Driver = 'Driver'
}

export interface IUserNotificationSettings {
  emailOnLogin?: boolean
  news?: boolean
}

export const userNotificationSettingsSchema: ObjectSchema<IUserNotificationSettings> =
  object({
    emailOnLogin: boolean(),
    news: boolean()
  })

export interface IUser {
  id: number
  firstName: string
  middleName?: string
  lastName: string
  createdAt: Date
  role: UserRole
  username: string
  registered: boolean
  password?: string
  email: string
  phone?: string
  refreshToken?: string
  notificationsSettings?: IUserNotificationSettings
  isProfilePictureUploaded: boolean
  companyId: number
}

export interface ISchemaUser
  extends Omit<
    IUser,
    | 'id'
    | 'createdAt'
    | 'role'
    | 'company'
    | 'companyId'
    | 'refreshToken'
    | 'isProfilePictureUploaded'
    | 'registered'
  > {}

export const userSchema: ObjectSchema<ISchemaUser> = object({
  firstName: string()
    .required('First Name is required.')
    .matches(nameRegExp, 'Incorrect name.'),
  middleName: string()
    .matches(nameRegExp, {
      excludeEmptyString: true,
      message: 'Incorrect name.'
    })
    .optional(),
  lastName: string()
    .required('Last Name is required.')
    .matches(nameRegExp, 'Incorrect name.'),
  username: string()
    .min(6, 'Minimum 6 characters.')
    .max(32, 'Maximum 32 characters.')
    .matches(/^[a-zA-Z0-9_]+$/, 'Incorrect username.')
    .required('Username is required.'),
  password: string()
    .min(8, 'Minimum 8 characters.')
    .max(64, 'Maximum 64 characters.')
    .optional(),
  notificationsSettings: userNotificationSettingsSchema.optional(),
  email: string().email('Invalid email.').required('Email is required.'),
  phone: string().test(phoneTest()).optional()
}).transform(yupTransformer)
