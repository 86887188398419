
import { HoverCard, HoverCardTrigger } from '@/components/ui/hover-card'
import { formatName } from '@/lib/utils'
import { IRider } from '@/types/riders.type'
import { FC } from 'react'
import RiderPreviewContent from './RiderPreviewContent'

interface IRiderPreviewProps {
  value: IRider
}

const RiderPreview: FC<IRiderPreviewProps> = (props: IRiderPreviewProps) => {
  return (
    <HoverCard>
      <HoverCardTrigger className='w-max' asChild>
        <span>{formatName(props.value, false)}</span>
      </HoverCardTrigger>
      <RiderPreviewContent value={props.value} />
    </HoverCard>
  )
}

export default RiderPreview
