import { useProfilePicture } from '@/hooks/queries/useProfilePicture'
import { cn } from '@/lib/utils'
import { IUser } from '@/types/users.type'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from './avatar'
import Spinner from './Spinner'

interface IProfilePictureProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  fallbackClassname?: string
  style?: CSSProperties
  user: IUser
}

const ProfilePicture: FC<IProfilePictureProps> = (
  props: IProfilePictureProps
) => {
  const profilePicture = useProfilePicture(
    props.user.id,
    props.user.isProfilePictureUploaded
  )

  return (
    <Avatar className={props.className} style={props.style}>
      <AvatarImage src={profilePicture.data} />
      <AvatarFallback className={cn('select-none', props.fallbackClassname)}>
        {profilePicture.isLoading ? (
          <Spinner />
        ) : (
          `${props.user.firstName[0].toUpperCase()}`
        )}
      </AvatarFallback>
    </Avatar>
  )
}
export default ProfilePicture
