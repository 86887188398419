import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import BrokerInput from '@/components/ui/inputs/BrokerInput'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import DriverInput from '@/components/ui/inputs/DriverInput'
import EnumInput from '@/components/ui/inputs/EnumInput'
import RiderInput from '@/components/ui/inputs/RiderInput'
import {
  createTripReportSchema,
  ICreateTripReport,
  ReportTheme
} from '@/types/reports.type'
import { ScheduleStatus } from '@/types/trips.type'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
  subMonths
} from 'date-fns'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TbCalendar, TbChevronRight } from 'react-icons/tb'
import { useCreateTripsReport } from './hooks/useCreateTripsReport'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { ChevronsUpDown } from 'lucide-react'

const TripsReportDialog: FC = () => {
  const { mutate: createReport } = useCreateTripsReport()

  const [open, setOpen] = useState<boolean>(false)

  const form = useForm<ICreateTripReport>({
    resolver: yupResolver(createTripReportSchema),
    defaultValues: {
      brokerFilterId: undefined,
      riderFilterId: undefined,
      status: undefined,
      name: `Trips Report (${format(startOfMonth(new Date()), 'MM.dd.yyyy')} - ${format(endOfMonth(new Date()), 'MM.dd.yyyy')})`,
      theme: ReportTheme.Light,
      range: {
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date())
      }
    }
  })

  const handleSubmit = (data: ICreateTripReport) => {
    createReport(data)
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant='ghost'>
          <TbCalendar className='min-h-4 min-w-4 mr-2' />
          Trips Report
        </Button>
      </DialogTrigger>
      <Form {...form}>
        <DialogContent>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className='flex flex-col gap-4'
          >
            <DialogHeader>
              <DialogTitle>Generate Trips Report</DialogTitle>
              <DialogDescription>
                This action will generate a new Trips Report. Processing may
                take some time, and you can monitor the status in the Reports
                table. Once processing is complete, the report will be available
                for download
              </DialogDescription>
            </DialogHeader>
            <div className='flex flex-row gap-4 justify-center w-full'>
              <FormField
                control={form.control}
                name='range.start'
                render={({ field }) => (
                  <FormItem className='w-full'>
                    <FormLabel>Starting At *</FormLabel>
                    <FormControl>
                      <DateInput
                        {...field}
                        type='date'
                        onBlur={value => {
                          if (value > form.getValues(`range.end`))
                            return form.setValue('range.end', value)

                          const difference = Math.abs(
                            differenceInMonths(
                              value,
                              form.getValues(`range.end`)
                            )
                          )

                          if (difference >= 3)
                            form.setValue('range.end', addMonths(value, 3))

                          field.onChange(value)

                          if (!form.getFieldState('name').isDirty)
                            form.setValue(
                              'name',
                              `Trips Report (${format(form.getValues('range.start'), 'MM.dd.yyyy')}) - (${format(form.getValues('range.end'), 'MM.dd.yyyy')})`
                            )
                        }}
                        className='w-full h-8'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <TbChevronRight className='self-end mb-2 min-w-4 min-h-4' />
              <FormField
                control={form.control}
                name='range.end'
                render={({ field }) => (
                  <FormItem className='w-full'>
                    <FormLabel>Ending At *</FormLabel>
                    <FormControl>
                      <DateInput
                        {...field}
                        type='date'
                        onBlur={value => {
                          if (value < form.getValues(`range.start`))
                            form.setValue(`range.start`, value)

                          const difference = Math.abs(
                            differenceInMonths(
                              value,
                              form.getValues(`range.start`)
                            )
                          )

                          if (difference >= 3)
                            form.setValue('range.start', subMonths(value, 3))

                          field.onChange(value)

                          if (!form.getFieldState('name').isDirty)
                            form.setValue(
                              'name',
                              `Trips Report (${format(form.getValues('range.start'), 'MM.dd.yyyy')}) - (${format(form.getValues('range.end'), 'MM.dd.yyyy')})`
                            )
                        }}
                        className='w-full h-8'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name='name'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name *</FormLabel>
                  <FormControl>
                    <Input {...field} className='w-full h-8' />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='brokerFilterId'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Broker Filter</FormLabel>
                  <FormControl>
                    <BrokerInput
                      {...field}
                      fieldClassName='w-full'
                      popoverClassName='w-96'
                      onChange={value => field.onChange(value?.id)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='riderFilterId'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Rider Filter</FormLabel>
                  <FormControl>
                    <RiderInput
                      {...field}
                      fieldClassName='w-full'
                      popoverClassName='w-96'
                      onChange={value => field.onChange(value?.id)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='riderFilterId'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Driver Filter</FormLabel>
                  <FormControl>
                    <DriverInput
                      {...field}
                      fieldClassName='w-full'
                      popoverClassName='w-96'
                      onChange={value => field.onChange(value?.id)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='status'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Status Filter</FormLabel>
                  <FormControl>
                    <EnumInput
                      {...field}
                      enum={ScheduleStatus}
                      fieldClassName='w-full'
                      popoverClassName='w-96'
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Collapsible>
              <CollapsibleTrigger asChild>
                <Button variant='link' className='p-0'>
                  <ChevronsUpDown className='h-4 w-4 mr-1' />
                  Advanced options
                </Button>
              </CollapsibleTrigger>
              <CollapsibleContent className='ml-4'>
                <FormField
                  control={form.control}
                  name='theme'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Theme *</FormLabel>
                      <FormControl>
                        <EnumInput
                          {...field}
                          required
                          value={field.value}
                          enum={ReportTheme}
                          fieldClassName='w-full h-8'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CollapsibleContent>
            </Collapsible>
            <DialogFooter>
              <DialogClose asChild>
                <Button variant='outline'>Close</Button>
              </DialogClose>
              <Button type='submit'>Create</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Form>
    </Dialog>
  )
}
export default TripsReportDialog

