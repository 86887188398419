import { Button } from '@/components/ui/button'
import Logo from '@/components/ui/logo'
import { FC } from 'react'
import { Link } from 'react-router-dom'

export interface IErrorPageProps {
  title: string
  description: string
  href: string
  button: string
}

const ErrorPage: FC<IErrorPageProps> = (props: IErrorPageProps) => {
  return (
    <div className='flex flex-col items-center justify-center min-h-[100dvh] gap-8 px-4 py-12 md:py-24'>
      <Logo className='w-32 h-32' />
      <div className='flex flex-col gap-4 text-center'>
        <h1 className='text-6xl font-bold'>{props.title}</h1>
        <h3 className='max-w-[600px] text-xl text-muted-foreground'>
          {props.description}
        </h3>
      </div>
      <Button asChild>
        <Link to={props.href}>{props.button}</Link>
      </Button>
    </div>
  )
}

export default ErrorPage
