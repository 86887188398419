import companyService from '@/services/company.service'
import { useQuery } from '@tanstack/react-query'

export const useLogo = (enabled?: boolean) =>
  useQuery({
    queryKey: ['logo'],
    enabled: enabled,
    queryFn: async () => await companyService.getLogoURL(),
    select: response => response.data,
    retry: 1,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  })

