import ScheduleStatusBadge from '@/components/tables/TripsTable/ScheduleStatusBadge'
import { formatAddress, formatName } from '@/lib/utils'
import { ITrip } from '@/types/trips.type'
import { FC } from 'react'
import { TbCircle, TbPencil } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { Button } from '../button'
import Copyable from '../copyable'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../hover-card'
import TimePreview from './TimePreview'

interface ITripPreviewProps {
  trip: ITrip
  children?: React.ReactNode
}

const TripPreview: FC<ITripPreviewProps> = (props: ITripPreviewProps) => {
  const navigate = useNavigate()

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        {props.children || props.trip.id}
      </HoverCardTrigger>
      <HoverCardContent className='flex flex-row gap-4 w-96'>
        <div className='flex flex-col gap-8'>
          {props.trip.schedules.map((schedule, index) => (
            <div key={index} className='flex flex-col gap-2 text-sm'>
              <div className='flex flex-row gap-2'>
                <span>
                  Rider: {formatName(schedule.rider)}{' '}
                  {schedule.guests!?.length > 0 &&
                    `(${schedule.guests?.length} guests)`}
                </span>
                <ScheduleStatusBadge value={schedule.status} />
              </div>
              <span>Mobility: {schedule.mobility}</span>
              {schedule.notes && <span>Notes: {schedule.notes}</span>}
              <div className='flex flex-row gap-2'>
                <div className='flex flex-col gap-0 place-items-center'>
                  <TbCircle className='w-3 h-3' />
                  <div className='h-32 w-[0.1px] bg-foreground' />
                  <TbCircle className='w-3 h-3' />
                </div>
                <div className='flex flex-col justify-between'>
                  <div className='flex flex-col gap-1'>
                    <TimePreview
                      value={schedule}
                      time='pickup'
                      className='flex flex-row gap-1'
                    />
                    <Copyable value={formatAddress(schedule.from, 'normal')}>
                      {formatAddress(schedule.from, 'normal')} (click to copy)
                    </Copyable>
                  </div>
                  <div className='flex flex-col gap-1'>
                    <Copyable value={formatAddress(schedule.to, 'normal')}>
                      {formatAddress(schedule.to, 'normal')} (click to copy)
                    </Copyable>
                    <TimePreview
                      value={schedule}
                      time='dropoff'
                      className='flex flex-row gap-1'
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Button
          className='rounded-full min-w-8 h-8'
          size='icon'
          onClick={() => navigate(`/trips/${props.trip.id}`)}
        >
          <TbPencil />
        </Button>
      </HoverCardContent>
    </HoverCard>
  )
}
export default TripPreview
