import { useToast } from '@/components/ui/use-toast'
import { formatName } from '@/lib/utils'
import tripsService from '@/services/trips.service'
import { IAPIError } from '@/types/common.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useUnassignTrip = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({
      id,
      uncombine
    }: {
      id: number
      uncombine?: boolean
    }) => {
      return await tripsService.unassign(id, uncombine)
    },
    onSuccess: async ({ data }) => {
      toast({
        title: 'Unassigned!',
        description: `Trip #${data.id} (${data.schedules.map(schedule => formatName(schedule.rider)).join(', ')}) was successfully unassigned.`
      })

      await queryClient.invalidateQueries({ queryKey: ['trips'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
