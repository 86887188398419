import { toast } from '@/components/ui/use-toast'
import tripsService from '@/services/trips.service'
import { IAPIError } from '@/types/common.type'
import { IImport } from '@/types/import.type'
import { useMutation } from '@tanstack/react-query'

export const useStartImport = () =>
  useMutation({
    mutationFn: async (data: IImport) => {
      return await tripsService.startImport(data)
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })

