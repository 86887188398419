import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TbMail } from 'react-icons/tb'
import { object, string } from 'yup'
import { useRequestPasswordRecovery } from '../../hooks/mutations/useRequestPasswordRecovery'

const RecoverPasswordDialog: FC = () => {
  const form = useForm<{
    email: string
  }>({
    resolver: yupResolver(
      object({
        email: string()
          .email('Invalid email address.')
          .required('Email is required.')
      })
    )
  })

  const [open, setOpen] = useState<boolean>(false)

  const recoverPassword = useRequestPasswordRecovery(true)

  return (
    <Form {...form}>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <span className='text-muted-foreground text-xs cursor-pointer'>
            Trouble logging in?
          </span>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Forgot your password?</DialogTitle>
            <DialogDescription>
              If you forgot password for your ManageNEMT account you can enter
              your email further to recover your password.
            </DialogDescription>
          </DialogHeader>
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input className='h-8' autoComplete='email' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <DialogFooter>
            <Button
              disabled={recoverPassword.isSuccess}
              onClick={form.handleSubmit(data =>
                recoverPassword.mutate(data.email, {
                  onSuccess: () => setOpen(false)
                })
              )}
              type='submit'
            >
              <TbMail className='h-4 w-4 mr-2' />
              Send recovery email
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Form>
  )
}
export default RecoverPasswordDialog
