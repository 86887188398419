import brokersService from '@/services/brokers.service'
import { useQuery } from '@tanstack/react-query'

export const useBrokers = (refetchOnWindowFocus = true) =>
  useQuery({
    queryKey: ['brokers'],
    queryFn: async () => await brokersService.getAll(),
    select: response => response.data,
    refetchOnWindowFocus: refetchOnWindowFocus
  })
