import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import ProfilePicture from '@/components/ui/profile-picture'
import { useLogout } from '@/hooks/mutations/useLogout'
import { useAppSelector } from '@/hooks/useAppSelector'
import { formatName } from '@/lib/utils'
import { getFeedback } from '@sentry/react'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  TbBell,
  TbBrush,
  TbChevronDown,
  TbChevronUp,
  TbLifebuoy,
  TbLogout,
  TbPassword,
  TbUser
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

const User: FC = () => {
  const [isDroppedDown, setIsDroppedDown] = useState<boolean>(false)

  const navigate = useNavigate()
  const user = useAppSelector(state => state.userState.user)

  const { mutate: logout } = useLogout()

  const feedback = useMemo(() => getFeedback(), [])
  const feedbackRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!feedbackRef.current) return () => {}
    return feedback?.attachTo(feedbackRef.current, {
      showBranding: false,
      isNameRequired: false,
      showName: false,
      showEmail: false,
      messagePlaceholder: 'Please, explain your problem.',
      useSentryUser: {
        name: 'name',
        email: 'email'
      }
    })
  }, [feedbackRef.current])

  return (
    <>
      <div ref={feedbackRef} />
      <div className='absolute flex p-2 right-0 rounded-lg cursor-pointer'>
        <DropdownMenu open={isDroppedDown} onOpenChange={setIsDroppedDown}>
          <DropdownMenuTrigger asChild>
            {user && (
              <div className='flex flex-row gap-2 place-items-center'>
                <ProfilePicture user={user} className='h-10 w-10' />
                <p className='hidden sm:block'>{formatName(user, false)}</p>
                {isDroppedDown ? <TbChevronUp /> : <TbChevronDown />}
              </div>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onClick={event => event.stopPropagation()}
            className='w-36 mt-2'
          >
            <DropdownMenuLabel>Settings</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => navigate('/preferences?tab=account')}
            >
              <TbPassword className='h-4 w-4 mr-2' />
              <span>Account</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => navigate('/preferences?tab=personal')}
            >
              <TbUser className='h-4 w-4 mr-2' />
              <span>Personal</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => navigate('/preferences?tab=notifications')}
            >
              <TbBell className='h-4 w-4 mr-2' />
              <span>Notifications</span>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => navigate('/preferences?tab=appearance')}
            >
              <TbBrush className='h-4 w-4 mr-2' />
              <span>Appearance</span>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => feedbackRef.current?.click()}>
              <TbLifebuoy className='h-4 w-4 mr-2' />
              <span>Report a Bug</span>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <DropdownMenuItem onSelect={event => event.preventDefault()}>
                  <TbLogout className='h-4 w-4 mr-2' />
                  <span>Logout</span>
                </DropdownMenuItem>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This action will log you out of your account, you will be
                    redirected to the login page.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={() => logout()}>
                    Logout
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  )
}

export default User
