import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card'
import { darkThemeStyles, lightThemeStyles } from '@/default-map-style'
import { useTheme } from '@/hooks/useTheme'
import { cn } from '@/lib/utils'
import { Theme } from '@/reducers/settingsSlice'
import { ISchemaTrip } from '@/types/trips.type'
import { Map, Marker } from '@vis.gl/react-google-maps'
import { FC, useMemo } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { TbMap } from 'react-icons/tb'

interface IMapPreviewProps {
  form: UseFormReturn<ISchemaTrip, any, undefined>
  index: number
  className?: string
}

const allowedMoveDistance = 1

const MapPreview: FC<IMapPreviewProps> = (props: IMapPreviewProps) => {
  const theme = useTheme()

  const from = useWatch({
    control: props.form.control,
    name: `schedules.${props.index}.from`
  })

  const to = useWatch({
    control: props.form.control,
    name: `schedules.${props.index}.to`
  })

  const center = useMemo(
    () => ({
      lat: (from?.latitude + to?.latitude) / 2,
      lng: (from?.longitude + to?.longitude) / 2
    }),
    [from, to]
  )

  return (
    from?.latitude &&
    to?.latitude && (
      <HoverCard>
        <HoverCardTrigger className={cn(props.className)}>
          <TbMap className='h-6 w-6' />
        </HoverCardTrigger>
        <HoverCardContent className='w-[600px] h-[600px]'>
          <Map
            className='w-full h-full'
            defaultCenter={center}
            defaultZoom={4}
            restriction={{
              latLngBounds: {
                north:
                  Math.max(from.latitude, to.latitude) + allowedMoveDistance,
                south:
                  Math.min(from.latitude, to.latitude) - allowedMoveDistance,
                west:
                  Math.min(from.longitude, to.longitude) - allowedMoveDistance,
                east:
                  Math.max(from.longitude, to.longitude) + allowedMoveDistance
              },
              strictBounds: true
            }}
            styles={theme === Theme.Light ? lightThemeStyles : darkThemeStyles}
            mapTypeId='roadmap'
            gestureHandling='greedy'
            disableDefaultUI={true}
            keyboardShortcuts={false}
          >
            <Marker
              position={{
                lat: from.latitude,
                lng: from.longitude
              }}
              icon={{
                url: '/icons/from.svg',
                scaledSize: {
                  height: 30,
                  width: 27,
                  equals: () => true
                }
              }}
            />
            <Marker
              position={{
                lat: to.latitude,
                lng: to.longitude
              }}
              icon={{
                url: '/icons/to.svg',
                scaledSize: {
                  height: 30,
                  width: 27,
                  equals: () => true
                }
              }}
            />
          </Map>
        </HoverCardContent>
      </HoverCard>
    )
  )
}
export default MapPreview
