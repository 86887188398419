import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { useCalled } from '@/hooks/mutations/useCalled'
import { useSetScheduleStatus } from '@/hooks/mutations/usetSetScheduleStatus'
import { useUncombineTrip } from '@/hooks/mutations/useUncombineTrip'
import { ISchedule, ITrip, ScheduleStatus } from '@/types/trips.type'
import { CellContext } from '@tanstack/react-table'
import { FC, useState } from 'react'
import {
  TbAnchor,
  TbDots,
  TbPencil,
  TbPhone,
  TbUnlink,
  TbUser,
  TbUserOff
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import ChangeStatusDialog from './ChangeStatusDialog'
import UnassignDialog from './UnassignDialog'

const TripActions: FC<CellContext<any, any>> = (
  props: CellContext<any, any>
) => {
  const navigate = useNavigate()

  const { mutate: setStatus } = useSetScheduleStatus()
  const { mutate: setCalled } = useCalled()
  const { mutate: uncombine } = useUncombineTrip()

  const [dialogStatus, setDialogStatus] = useState<ScheduleStatus>()
  const [unassigableTrip, setUnassignableTrip] = useState<ITrip>()

  const current: ISchedule = props.row.original
  const parent: ITrip | undefined = props.row.getParentRow()?.original

  const handleStatusChange = (status: ScheduleStatus) => {
    if (status === ScheduleStatus.Canceled || status === ScheduleStatus.NoShow)
      return setDialogStatus(status)

    return setStatus({
      schedule: current,
      status: status
    })
  }

  return (
    <>
      <ChangeStatusDialog
        schedule={current}
        changeableStatus={dialogStatus}
        setChangeableStatus={setDialogStatus}
      />
      <UnassignDialog
        unassignableTrip={unassigableTrip}
        setUnassignableTrip={setUnassignableTrip}
      />
      {parent ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant='ghost'
              className='h-8 w-8 p-0'
              onClick={event => event.stopPropagation()}
            >
              <TbDots className='h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => navigate(`/trips/${parent.id}`)}>
              <TbPencil className='mr-2 h-4 w-4' />
              Edit Trip
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => navigate(`/drivers/${parent.shift?.driverId}`)}
              disabled={!!!parent.shift}
            >
              <TbUser className='mr-2 h-4 w-4' />
              Edit Driver
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => navigate(`/riders/${current.riderId}`)}
            >
              <TbAnchor className='mr-2 h-4 w-4' />
              Edit Rider
            </DropdownMenuItem>
            {parent.shift && (
              <DropdownMenuItem onClick={() => setUnassignableTrip(parent)}>
                <TbUserOff className='mr-2 h-4 w-4' />
                Unassign
              </DropdownMenuItem>
            )}
            {current.willCall && (
              <DropdownMenuItem onClick={() => setCalled(current)}>
                <TbPhone className='mr-2 h-4 w-4' />
                Person Called
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>Status</DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                <DropdownMenuRadioGroup
                  onValueChange={value =>
                    handleStatusChange(value as ScheduleStatus)
                  }
                  value={current.status}
                >
                  {parent.shift ? (
                    <DropdownMenuRadioItem value={ScheduleStatus.Assigned}>
                      Assigned
                    </DropdownMenuRadioItem>
                  ) : (
                    <DropdownMenuRadioItem value={ScheduleStatus.Unassigned}>
                      Unassigned
                    </DropdownMenuRadioItem>
                  )}
                  <DropdownMenuRadioItem value={ScheduleStatus.NoShow}>
                    No Show
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.ArrivedAtDO}>
                    Arrived (DO)
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.ArrivedAtPU}>
                    Arrived (PU)
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.Canceled}>
                    Canceled
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.Completed}>
                    Completed
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.InRoute}>
                    In Route
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={ScheduleStatus.InTransit}>
                    In Transit
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant='ghost'
              className='h-8 w-8 p-0'
              onClick={event => event.stopPropagation()}
            >
              <TbDots className='h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => uncombine(current.id)}>
              <TbUnlink className='mr-2 h-4 w-4' />
              Uncombine
            </DropdownMenuItem>
            {(current as unknown as ITrip).shift && (
              <DropdownMenuItem
                onClick={() => setUnassignableTrip(current as unknown as ITrip)}
              >
                <TbUserOff className='mr-2 h-4 w-4' />
                Unassign
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  )
}
export default TripActions
