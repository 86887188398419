import { Button } from '@/components/ui/button'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel
} from '@/components/ui/context-menu'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import ProfilePicture from '@/components/ui/profile-picture'
import { useAppSelector } from '@/hooks/useAppSelector'
import { useReinvite } from '@/hooks/mutations/useReinvite'
import { formatName } from '@/lib/utils'
import { IAdmin } from '@/types/admins.type'
import { format } from 'date-fns'
import { FC } from 'react'
import { TbDots, TbMail } from 'react-icons/tb'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom'
import AdminDeleteDialog from './AdminDeleteDialog'

const AdminsTable: FC<ICustomizableTableProps<IAdmin>> = (
  props: ICustomizableTableProps<IAdmin>
) => {
  const navigate = useNavigate()
  const { mutate: reinvite } = useReinvite()

  const user = useAppSelector(selector => selector.userState.user)

  return (
    <Table
      {...props}
      initialState={{
        sorting: [
          {
            id: 'username',
            desc: false
          }
        ]
      }}
      contextMenu={(row: IAdmin) => (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem onClick={() => navigate(`/admins/${row.id}`)}>
            Edit
          </ContextMenuItem>
          <AdminDeleteDialog admin={row}>
            <ContextMenuItem>Delete</ContextMenuItem>
          </AdminDeleteDialog>
        </ContextMenuContent>
      )}
      columns={[
        {
          accessorKey: 'name',
          accessorFn: (admin: IAdmin) => admin.user,
          header: 'Name',
          cell: props => (
            <div className='flex flex-row place-items-center gap-4'>
              <ProfilePicture user={props.getValue()} />
              <span>{formatName(props.getValue())}</span>
            </div>
          )
        },
        {
          accessorKey: 'username',
          accessorFn: (admin: IAdmin) => admin.user.username,
          header: 'Username',
          filterFn: 'includesString',
          cell: props => <p>{props.getValue()}</p>
        },
        {
          accessorKey: 'email',
          accessorFn: (admin: IAdmin) => admin.user,
          header: 'Email',
          filterFn: 'includesString',
          cell: props => (
            <div className='flex flex-row place-items-center gap-2'>
              <span className='break-all text-sm'>
                {props.getValue().email}
              </span>
              {!props.getValue().registered && (
                <HoverCard>
                  <HoverCardTrigger>
                    <Button
                      className='h-8 w-8'
                      variant='outline'
                      size='icon'
                      onClick={() => reinvite(props.getValue().id)}
                    >
                      <TbMail />
                    </Button>
                  </HoverCardTrigger>
                  <HoverCardContent>
                    Resend invitation link to {props.getValue().email}
                  </HoverCardContent>
                </HoverCard>
              )}
            </div>
          )
        },
        {
          accessorKey: 'phone',
          accessorFn: (admin: IAdmin) => admin.user.phone,
          header: 'Phone',
          filterFn: 'includesString',
          cell: props => (
            <p>{formatPhoneNumberIntl(props.getValue()) || props.getValue()}</p>
          )
        },
        {
          accessorKey: 'createdAt',
          accessorFn: (admin: IAdmin) =>
            admin.user.createdAt && format(admin.user.createdAt, 'MM.dd.yyyy'),
          header: 'Registration Date',
          cell: props => <p>{props.getValue()}</p>
        },
        {
          id: 'actions',
          cell: props => (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant='ghost' className='h-8 w-8 p-0'>
                  <TbDots className='h-4 w-4' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                onClick={event => event.stopPropagation()}
                align='end'
              >
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() =>
                    props.row.original.id === user?.id
                      ? navigate(`/preferences?tab=account`)
                      : navigate(`/admins/${props.row.original.id}`)
                  }
                >
                  Edit
                </DropdownMenuItem>
                <AdminDeleteDialog admin={props.row.original}>
                  <DropdownMenuItem>Delete</DropdownMenuItem>
                </AdminDeleteDialog>
              </DropdownMenuContent>
            </DropdownMenu>
          ),
          size: 100
        }
      ]}
    />
  )
}
export default AdminsTable
