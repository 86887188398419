import { FC, ReactNode } from 'react'

interface ITabHeaderProps {
  title: ReactNode
  description: ReactNode
}

const TabHeader: FC<ITabHeaderProps> = (props: ITabHeaderProps) => {
  return (
    <div className='flex flex-col gap-1'>
      <h3 className='text-lg font-medium'>{props.title}</h3>
      <h4 className='text-muted-foreground text-sm'>{props.description}</h4>
    </div>
  )
}
export default TabHeader

