import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import { useSetScheduleStatus } from '@/hooks/mutations/usetSetScheduleStatus'
import { formatScheduleStatus } from '@/lib/utils'
import { ISchedule, ScheduleStatus } from '@/types/trips.type'
import { FC } from 'react'

interface IChangeStatusDialogProps {
  schedule: ISchedule
  changeableStatus: ScheduleStatus | undefined
  setChangeableStatus: (value: ScheduleStatus | undefined) => void
}

const ChangeStatusDialog: FC<IChangeStatusDialogProps> = (
  props: IChangeStatusDialogProps
) => {
  const { mutate: setStatus } = useSetScheduleStatus()

  return (
    <Dialog
      onOpenChange={() => props.setChangeableStatus(undefined)}
      open={!!props.changeableStatus}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Change status to {formatScheduleStatus(props.changeableStatus!)}
          </DialogTitle>
          <DialogDescription>
            You can update the status of this trip only for this trip or for all
            "Assigned" or "Unassigned" trips of this rider for selected day. You
            can change status later.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                setStatus({
                  schedule: props.schedule,
                  forAllRiderTrips: true,
                  status: props.changeableStatus!
                })
              }}
              variant='destructive'
            >
              All trips of this rider
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              onClick={() => {
                setStatus({
                  schedule: props.schedule,
                  forAllRiderTrips: false,
                  status: props.changeableStatus!
                })
              }}
            >
              This trip only
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
export default ChangeStatusDialog

