import { useToast } from '@/components/ui/use-toast'
import { formatName } from '@/lib/utils'
import tripsService from '@/services/trips.service'
import { IAPIError } from '@/types/common.type'
import { ISchedule } from '@/types/trips.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCalled = () => {
  const queryClient = useQueryClient()

  const { toast } = useToast()

  return useMutation({
    mutationFn: async (schedule: ISchedule) => {
      await tripsService.calledSchedule(
        schedule.id as number,
        new Date().toISOString()
      )

      return schedule
    },
    onSuccess: data => {
      toast({
        title: 'Updated!',
        description: `Schedule #${data.id} (${formatName(data.rider)}) was updated successfully.`
      })

      queryClient.invalidateQueries({ queryKey: ['trips'] })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
