import vehiclesService from '@/services/vehicles.service'
import { useQuery } from '@tanstack/react-query'

export const useVehicles = (refetchOnWindowFocus = true) =>
  useQuery({
    queryKey: ['vehicles'],
    queryFn: async () => await vehiclesService.getAll(),
    select: response => response.data,
    refetchOnWindowFocus: refetchOnWindowFocus
  })
