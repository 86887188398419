import { setUser } from '@sentry/react'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import SideBar from './components/layout/sideBar/SideBar'
import SideBarContext from './components/layout/sideBar/SideBarContext'
import TopBar from './components/layout/topBar/TopBar'
import { useAppSelector } from './hooks/useAppSelector'
import { formatName } from './lib/utils'
import ErrorPage from './pages/ErrorPage'

const Layout = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const isAuthorized = !!window.localStorage.getItem('accessToken')
  const user = useAppSelector(state => state.userState.user)

  useEffect(() => {
    if (!user) return () => {}

    return setUser({
      name: formatName(user),
      email: user?.email,
      username: user?.username,
      id: user?.id
    })
  }, [user])

  return isAuthorized ? (
    <SideBarContext.Provider value={{ isCollapsed, setIsCollapsed }}>
      <div className='flex flex-row h-screen w-screen overflow-hidden gap-0'>
        <SideBar />
        <div className='flex flex-col gap-4 h-full w-full overflow-y-auto lg:p-6 p-3 pb-6'>
          <TopBar />
          <Outlet />
        </div>
      </div>
    </SideBarContext.Provider>
  ) : (
    <ErrorPage
      title='You are unauthorized!'
      description='Sorry, but you don’t have permissions to access this page. Please log in or ensure you have the necessary access rights.'
      href='/login'
      button='Go to the login page'
    />
  )
}

export default Layout
