import ridersService from '@/services/riders.service'
import { useQuery } from '@tanstack/react-query'

export const useRiders = (refetchOnWindowFocus = true) =>
  useQuery({
    queryKey: ['riders'],
    queryFn: async () => await ridersService.getAll(),
    select: response => response.data,
    refetchOnWindowFocus: refetchOnWindowFocus
  })
