import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import FileInput from '@/components/ui/inputs/FileInput'
import Spinner from '@/components/ui/Spinner'
import { useUploadProfilePicture } from '@/hooks/mutations/useUploadProfilePicture'
import { cn } from '@/lib/utils'
import { IUser } from '@/types/users.type'
import { DialogClose } from '@radix-ui/react-dialog'
import { FC, useState } from 'react'
import { TbPencil, TbPhoto } from 'react-icons/tb'
import ProfilePicture from './profile-picture'

interface IEditableProfilePictureProps {
  user: IUser
  className?: string
}

const EditableProfilePicture: FC<IEditableProfilePictureProps> = (
  props: IEditableProfilePictureProps
) => {
  const [file, setFile] = useState<File>()

  const uploadProfilePicture = useUploadProfilePicture()

  const submit = () => {
    if (!file) return

    uploadProfilePicture.mutate({
      id: props.user.id,
      file: file
    })
  }

  return (
    <div className={cn('relative w-max h-max', props.className)}>
      <ProfilePicture
        user={props.user}
        title={`${props.user.username}'s profile picture`}
        className='h-24 w-24 text-4xl'
      />
      <Dialog>
        <DialogTrigger asChild>
          <Button
            variant='outline'
            className='absolute -right-1 -top-1 h-8 w-8 p-2 rounded-full'
            size='icon'
            type='button'
            title='Update profile picture'
          >
            <TbPencil />
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Upload profile picture</DialogTitle>
            <DialogDescription>
              Select a new image below and click "Upload" to update your picture
              profile.
            </DialogDescription>
          </DialogHeader>
          <FileInput
            accept='.png|.jpeg|.jpg'
            required
            onChange={event => {
              if (event.target.files?.[0]) setFile(event.target.files[0])
            }}
          />
          <DialogFooter>
            <DialogClose asChild>
              <Button
                disabled={!file || uploadProfilePicture.isPending}
                onClick={submit}
                type='button'
              >
                Upload
                {uploadProfilePicture.isPending ? (
                  <Spinner className='ml-2 h-4 w-4' />
                ) : (
                  <TbPhoto className='ml-2 h-4 w-4' />
                )}
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default EditableProfilePicture
