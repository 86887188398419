import usersService from '@/services/users.service'
import { useQuery } from '@tanstack/react-query'

export const useProfilePicture = (id: number, enabled?: boolean) =>
  useQuery({
    queryKey: ['profilePicture', id],
    enabled: enabled,
    queryFn: async () => await usersService.getProfilePicture(id),
    select: response => response.data,
    retry: 1,
    refetchInterval: 3600 * 1000,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  })
