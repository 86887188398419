import {
  Table as PrimitiveTable,
  TableHeader as PrimitiveTableHeader
} from '@/components/ui/table'
import { useAppSelector } from '@/hooks/useAppSelector'
import { cn } from '@/lib/utils'
import { DNDType } from '@/types/dnd.type'
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  InitialTableState,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { FC, ReactNode, useEffect, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import {
  TbChevronLeft,
  TbChevronRight,
  TbChevronsLeft,
  TbChevronsRight
} from 'react-icons/tb'
import { Button } from '../button'
import { ContextMenuContent } from '../context-menu'
import TableBody from './TableBody'
import TableHead from './TableHead'

export interface IItem<T> {
  onClick?: (tableRow: T) => any
  children?: IItem<T>[]
  disabled?: boolean
  title: string
}

export interface ITableDNDTable<T> {
  draggable: boolean
  droppable?: 'table'
  onDrop?: (item: any) => void
  canDrop?: (item: any) => boolean
  deps?: unknown[]
  type: DNDType
}

export interface ITableDNDRow<T> {
  draggable: boolean
  droppable?: 'row'
  onDrop?: (item: any, original?: T) => void
  canDrop?: (item: any) => boolean
  deps?: unknown[]
  type: DNDType
}

export interface ITableDNDUndefined<T> {
  draggable: boolean
  droppable?: undefined | false
  onDrop?: undefined
  canDrop?: undefined
  deps?: unknown[]
  type: DNDType
}

export type ITableDND<T> =
  | ITableDNDTable<T>
  | ITableDNDRow<T>
  | ITableDNDUndefined<T>

export interface ICustomizableTableProps<T> {
  dnd?: ITableDND<T>
  emptyElement?: ReactNode
  data: T[]
  getSubRowsFn?: (row: T, index: number) => T[]
  onSelectionChange?: (data: T | undefined, row: Row<T> | undefined) => any
  columnFilters?: ColumnFiltersState
  initialState?: InitialTableState
  className?: string
}

interface ITableProps<T> extends ICustomizableTableProps<T> {
  disablePagination?: boolean
  contextMenu?: (
    data: T,
    row: Row<T>
  ) => React.ReactElement<typeof ContextMenuContent>
  defaultColumn?: Partial<ColumnDef<T, unknown>> | undefined
  columns: ColumnDef<T, any>[]
}

const Table: FC<ITableProps<any>> = <T,>(props: ITableProps<T>) => {
  const columns = useMemo<ColumnDef<T, any>[]>(
    () => props.columns,
    [props.columns]
  )

  const table = useReactTable({
    data: props.data,
    state: {
      ...(props.columnFilters && { columnFilters: props.columnFilters })
    },
    initialState: {
      pagination: {
        pageSize: 15
      },
      ...props.initialState
    },
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    ...(!props.disablePagination && {
      getPaginationRowModel: getPaginationRowModel()
    }),
    getSubRows: props.getSubRowsFn,
    enableColumnResizing: true,
    enableRowSelection: true,
    enableSubRowSelection: false,
    enableMultiRowSelection: false,
    getRowCanExpand: row => row.getLeafRows().length > 1,
    columnResizeMode: 'onChange',
    defaultColumn: props.defaultColumn
      ? props.defaultColumn
      : {
          size: 300
        }
  })

  const contextMenuEnabled = useAppSelector(
    state => state.settingsState.enableContextMenu
  )

  const [, dropRef] = useDrop(
    () => ({
      accept: typeof props.dnd?.type,
      drop: (item: any) => {
        props.dnd?.onDrop?.(item)
        return item
      },
      canDrop: props.dnd?.canDrop,
    }),
    props.dnd?.deps
  )

  useEffect(() => {
    table.toggleAllRowsExpanded()
  }, [table])

  return props.data.length === 0 && props.emptyElement ? (
    <div
      className={cn(
        'flex justify-center place-items-center rounded-md border border-dashed shadow-sm w-full h-full',
        props.className
      )}
    >
      {props.emptyElement}
    </div>
  ) : (
    <div className={cn('flex flex-col gap-4', props.className)}>
      <div
        className={`rounded-md border shadow-sm overflow-auto w-full h-full`}
        ref={props.dnd?.droppable === 'table' ? dropRef : undefined}
      >
        <PrimitiveTable className='w-full h-full'>
          <PrimitiveTableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHead key={headerGroup.id} headerGroup={headerGroup} />
            ))}
          </PrimitiveTableHeader>
          <TableBody
            rows={table.getRowModel().rows}
            contextMenu={contextMenuEnabled ? props.contextMenu : undefined}
            dnd={props.dnd}
            onSelectionChange={props.onSelectionChange}
          />
        </PrimitiveTable>
      </div>
      {!props.disablePagination && (
        <div className='flex flex-col place-items-center gap-4'>
          <div className='flex flex-row gap-2 place-items-center'>
            <Button
              variant='outline'
              size='icon'
              type='button'
              className='w-8 h-8'
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.setPageIndex(0)}
            >
              <TbChevronsLeft />
            </Button>
            <Button
              variant='outline'
              size='icon'
              type='button'
              className='w-8 h-8'
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
            >
              <TbChevronLeft />
            </Button>
            <span className='text-sm'>
              {table.getState().pagination.pageIndex +
                Number(table.getPageCount() > 0)}{' '}
              of {table.getPageCount()}
            </span>
            <Button
              variant='outline'
              size='icon'
              type='button'
              className='w-8 h-8'
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
            >
              <TbChevronRight />
            </Button>
            <Button
              variant='outline'
              size='icon'
              type='button'
              className='w-8 h-8'
              disabled={!table.getCanNextPage()}
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            >
              <TbChevronsRight />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Table
