import { Button } from '@/components/ui/button'
import EditableProfilePicture from '@/components/ui/editable-profile-picture'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { ISchemaUser, IUser, userSchema } from '@/types/users.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TbUser } from 'react-icons/tb'
import { useUpdateMe } from '../../hooks/useUpdateMe'
import TabHeader from '../../components/tab-header'

interface IPersonalTab {
  me: IUser
}

const PersonalTab: FC<IPersonalTab> = (props: IPersonalTab) => {
  const form = useForm<
    Pick<ISchemaUser, 'firstName' | 'middleName' | 'lastName'>
  >({
    resolver: yupResolver(
      userSchema.pick(['firstName', 'middleName', 'lastName'])
    )
  })

  const { mutate: submit } = useUpdateMe()

  useEffect(() => form.reset(props.me), [props.me])

  return (
    <Form {...form}>
      <form
        className='flex flex-col gap-6'
        onSubmit={form.handleSubmit(data => submit(data as IUser))}
      >
        <TabHeader title='Personal' description='Make changes to your personal information here.' />
        <Separator />
        <div className='flex flex-row gap-4 w-[600px]'>
          <EditableProfilePicture user={props.me} />
          <div className='flex flex-col gap-4 w-full'>
            <FormField
              control={form.control}
              name='firstName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name *</FormLabel>
                  <FormControl>
                    <Input className='max-w-full h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='middleName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Middle Name</FormLabel>
                  <FormControl>
                    <Input className='max-w-full h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='lastName'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name *</FormLabel>
                  <FormControl>
                    <Input className='max-w-full h-8' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <Button className='w-max h-8' type='submit'>
          <TbUser className='h-4 w-4 mr-2' />
          Update Personal
        </Button>
      </form>
    </Form>
  )
}
export default PersonalTab
