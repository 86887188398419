import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/ui/tooltip'
import { useAppDispatch } from '@/hooks/useAppDispatch'
import { useAppSelector } from '@/hooks/useAppSelector'
import {
  MapType,
  setDispatchAutoRefresh,
  setEnableContextMenu,
  setMapType,
  setTheme,
  Theme
} from '@/reducers/settingsSlice'
import { FC } from 'react'
import { TbAlertTriangleFilled } from 'react-icons/tb'
import TabHeader from '../../components/tab-header'
import SwitchSetting from '../../components/switch-setting'

const AppearanceTab: FC = () => {
  const dispatch = useAppDispatch()

  const settings = useAppSelector(state => state.settingsState)

  return (
    <div className='flex flex-col gap-6 w-[600px]'>
      <TabHeader
        title='Appearance'
        description='Customize your dashboard appearance here.'
      />
      <Separator />
      <div className='flex flex-col gap-2'>
        <TabHeader
          title='Theme'
          description='Choose your favourite theme for dashboard.'
        />
        <RadioGroup
          onValueChange={value => {
            dispatch(setTheme(value as Theme))
          }}
          className='flex flex-row flex-wrap'
        >
          <label className='border-2 [&:has([data-state=checked])]:border-primary border-background p-1 rounded-xl'>
            <Card className='w-max h-max dark:invert'>
              <RadioGroupItem
                value={Theme.Light}
                className='sr-only'
                data-state={settings.theme === Theme.Light && 'checked'}
              />
              <CardHeader>
                <CardTitle>Light</CardTitle>
              </CardHeader>
              <CardContent className='flex flex-col gap-1'>
                <div className='flex flex-row gap-2'>
                  <Skeleton className='h-4 w-4 rounded-full animate-none' />
                  <Skeleton className='h-4 w-16 rounded-full animate-none' />
                </div>
                <Skeleton className='h-4 w-32 animate-none' />
              </CardContent>
            </Card>
          </label>
          <label className='border-2 [&:has([data-state=checked])]:border-primary border-background p-1 rounded-xl'>
            <Card className='w-max h-max dark'>
              <RadioGroupItem
                value={Theme.Dark}
                className='sr-only'
                data-state={settings.theme === Theme.Dark && 'checked'}
              />
              <CardHeader>
                <CardTitle>Dark</CardTitle>
              </CardHeader>
              <CardContent className='flex flex-col gap-1'>
                <div className='flex flex-row gap-2'>
                  <Skeleton className='h-4 w-4 rounded-full animate-none' />
                  <Skeleton className='h-4 w-16 rounded-full animate-none' />
                </div>
                <Skeleton className='h-4 w-32 animate-none' />
              </CardContent>
            </Card>
          </label>
          <label className='border-2 [&:has([data-state=checked])]:border-primary border-background p-1 rounded-xl'>
            <Card className='w-max h-max dark'>
              <RadioGroupItem
                value={Theme.System}
                className='sr-only'
                data-state={settings.theme === Theme.System && 'checked'}
              />
              <CardHeader>
                <CardTitle>System</CardTitle>
              </CardHeader>
              <CardContent className='flex flex-col gap-1'>
                <div className='flex flex-row gap-2'>
                  <Skeleton className='h-4 w-4 dark:invert rounded-full animate-none' />
                  <Skeleton className='h-4 w-16 dark:invert rounded-full animate-none' />
                </div>
                <Skeleton className='h-4 w-32 dark:invert animate-none' />
              </CardContent>
            </Card>
          </label>
        </RadioGroup>
      </div>
      <div className='flex flex-col gap-2'>
        <TabHeader title='Map View' description='Choose default map view.' />
        <RadioGroup
          onValueChange={value => dispatch(setMapType(value as MapType))}
          className='flex flex-row flex-wrap'
        >
          <label className='border-2 [&:has([data-state=checked])]:border-primary border-background p-1 rounded-xl'>
            <Card className='relative w-[250px] h-48 bg-[url(/map-types/roadmap.png)]'>
              <RadioGroupItem
                value={MapType.Roadmap}
                className='sr-only'
                data-state={settings.mapType === MapType.Roadmap && 'checked'}
              />
              <CardHeader className='backdrop-blur-[2px] backdrop-brightness-[60%] h-24 rounded-t-md'>
                <CardTitle className='text-white'>Default</CardTitle>
                <CardDescription className='text-white/80'>
                  Classic schematic map.
                </CardDescription>
              </CardHeader>
            </Card>
          </label>
          <label className='border-2 [&:has([data-state=checked])]:border-primary border-background p-1 rounded-xl'>
            <Card className='relative w-[250px] h-48 bg-[url(/map-types/satellite.png)]'>
              <RadioGroupItem
                value={MapType.Satellite}
                className='sr-only'
                data-state={settings.mapType === MapType.Satellite && 'checked'}
              />
              <CardHeader className='backdrop-blur-[2px] backdrop-brightness-75 h-24 rounded-t-md'>
                <CardTitle className='text-white'>Satellite</CardTitle>
                <CardDescription className='text-white/80'>
                  High-resolution satellite map.
                </CardDescription>
              </CardHeader>
            </Card>
          </label>
          <label className='border-2 [&:has([data-state=checked])]:border-primary border-background p-1 rounded-xl'>
            <Card className='relative w-[250px] h-48 bg-[url(/map-types/hybrid.png)]'>
              <RadioGroupItem
                value={MapType.Hybrid}
                className='sr-only'
                data-state={settings.mapType === MapType.Hybrid && 'checked'}
              />
              <CardHeader className='backdrop-blur-[2px] backdrop-brightness-75 h-24 rounded-t-md'>
                <CardTitle className='text-white'>Hybrid</CardTitle>
                <CardDescription className='text-white/80'>
                  Satellite map with labels.
                </CardDescription>
              </CardHeader>
            </Card>
          </label>
          <label className='border-2 [&:has([data-state=checked])]:border-primary border-background p-1 rounded-xl'>
            <Card className='relative w-[250px] h-48 bg-[url(/map-types/terrain.png)]'>
              <RadioGroupItem
                value={MapType.Terrain}
                className='sr-only'
                data-state={settings.mapType === MapType.Terrain && 'checked'}
              />
              <CardHeader className='backdrop-blur-[2px] backdrop-brightness-[60%] h-24 rounded-t-md'>
                <CardTitle className='text-white'>Terrain</CardTitle>
                <CardDescription className='text-white/80'>
                  Map with detailed terrain.
                </CardDescription>
              </CardHeader>
            </Card>
          </label>
        </RadioGroup>
      </div>
      <div className='flex flex-col gap-4'>
        <TabHeader
          title='Miscellaneous'
          description='Change additional settings.'
        />
        <SwitchSetting
          checked={settings.dispatchAutoRefresh}
          icon={
            <Tooltip>
              <TooltipTrigger>
                <TbAlertTriangleFilled
                  className='text-orange-300/80'
                  title='This feature is experimental.'
                />
              </TooltipTrigger>
              <TooltipContent>
                This feature is Experimental. Enabling this can cause some user
                experience issues.
              </TooltipContent>
            </Tooltip>
          }
          onCheckedChange={checked => dispatch(setDispatchAutoRefresh(checked))}
          title='Dispatch page auto refresh'
          description='Automatically refresh information on dispatch page every 30 seconds.'
        />
        <SwitchSetting
          checked={settings.enableContextMenu}
          onCheckedChange={checked => dispatch(setEnableContextMenu(checked))}
          title='Context Menu'
          description='Enable context menu for tables with a right-click.'
        />
      </div>
    </div>
  )
}
export default AppearanceTab
