import DriverPreview from '@/components/ui/previews/DriverPreview'
import RiderPreview from '@/components/ui/previews/RiderPreview'
import TimePreview from '@/components/ui/previews/TimePreview'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { ISchedule, ITrip } from '@/types/trips.type'
import { Row } from '@tanstack/react-table'
import { FC } from 'react'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'
import AddressesCell from './AdrdessesCell'
import ScheduleContextMenu from './ScheduleContextMenu'
import ScheduleStatusBadge from './ScheduleStatusBadge'
import TripActions from './TripActions'
import {
  filterTripAddress,
  filterTripDriver,
  filterTripRider,
  filterTripStatus,
  sortTripAddresses,
  sortTripDrivers,
  sortTripPlain,
  sortTripRiders,
  sortTripTimes
} from './tripUtils'

const TripTable: FC<ICustomizableTableProps<ITrip>> = (
  props: ICustomizableTableProps<ITrip>
) => {
  return (
    <Table
      {...props}
      getSubRowsFn={trip => trip.schedules}
      initialState={{
        sorting: [
          {
            id: 'pickupTime',
            desc: false
          }
        ],
        pagination: {
          pageSize: 20
        },
        ...props.initialState
      }}
      contextMenu={(schedule: ISchedule, row: Row<any>) => (
        <ScheduleContextMenu
          current={schedule}
          parent={row.getParentRow()?.original}
        />
      )}
      columns={[
        {
          header: '#',
          accessorKey: 'expander',
          size: 20,
          enableSorting: false,
          filterFn: filterTripStatus,
          accessorFn: data => data.status,
          cell: props => (
            <div>
              {props.row.getCanExpand() && (
                <button
                  onClick={props.row.getToggleExpandedHandler()}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  {props.row.getIsExpanded() ? (
                    <TbChevronUp />
                  ) : (
                    <TbChevronDown />
                  )}
                </button>
              )}
              {props.getValue() && (
                <ScheduleStatusBadge value={props.getValue()} />
              )}
            </div>
          )
        },
        {
          accessorKey: 'rider',
          header: 'Rider',
          accessorFn: data => data.rider,
          sortingFn: sortTripRiders,
          filterFn: filterTripRider,
          cell: props =>
            props.getValue() ? (
              <RiderPreview value={props.getValue()} />
            ) : (
              <span>{props.row.original.schedules.length} riders</span>
            )
        },
        {
          accessorKey: 'driver',
          header: 'Driver',
          accessorFn: data => data.shift?.driver,
          sortingFn: sortTripDrivers,
          filterFn: filterTripDriver,
          cell: props =>
            props.row.getParentRow()?.original?.shift && (
              <DriverPreview
                value={props.row.getParentRow()?.original.shift.driver}
              />
            )
        },
        {
          accessorKey: 'addresses',
          header: 'From & To',
          filterFn: filterTripAddress,
          sortingFn: sortTripAddresses,
          accessorFn: data => data,
          cell: props => <AddressesCell {...props} />
        },
        {
          accessorKey: 'pickupTime',
          header: 'Pickup Time',
          accessorFn: data => data,
          sortingFn: sortTripTimes,
          cell: props => <TimePreview value={props.getValue()} time='pickup' />
        },
        {
          accessorKey: 'dropoffTime',
          header: 'Dropoff Time',
          accessorFn: data => data,
          sortingFn: sortTripTimes,
          cell: props => <TimePreview value={props.getValue()} time='dropoff' />
        },
        {
          accessorKey: 'mobility',
          header: 'Mobility',
          sortingFn: sortTripPlain,
          accessorFn: data => data?.mobility,
          cell: props => <span>{props.getValue()}</span>
        },
        {
          id: 'actions',
          size: 20,
          cell: props => <TripActions {...props} />
        }
      ]}
    />
  )
}

export default TripTable
