import adminsService from '@/services/admins.service'
import { useQuery } from '@tanstack/react-query'

export const useAdmins = (refetchOnWindowFocus = true) =>
  useQuery({
    queryKey: ['admins'],
    queryFn: async () => await adminsService.getAll(),
    select: response => response.data,
    refetchOnWindowFocus: refetchOnWindowFocus
  })
