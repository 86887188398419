import { cn } from '@/lib/utils'
import { FC, HTMLAttributes } from 'react'
import { useToast } from './use-toast'

interface ICopyableProps extends HTMLAttributes<HTMLSpanElement> {
  value: string
}

const Copyable: FC<ICopyableProps> = (props: ICopyableProps) => {
  const { toast } = useToast()

  const handleClick = async () => {
    if (!window.isSecureContext) return

    await navigator.clipboard.writeText(props.value).then(() =>
      toast({
        title: 'Copied!',
        description: `${props.value} was copied to your clipboard.`,
        duration: 750
      })
    )
  }

  return (
    <span
      {...props}
      className={cn('cursor-pointer', props.className)}
      onClick={handleClick}
    >
      {props.children}
    </span>
  )
}
export default Copyable

