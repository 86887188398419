import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum Theme {
  Dark = 'dark',
  Light = 'light',
  System = 'system'
}

export enum MapType {
  Roadmap = 'roadmap',
  Satellite = 'satellite',
  Hybrid = 'hybrid',
  Terrain = 'terrain'
}

const initialState = {
  theme: Theme.System,
  mapType: MapType.Roadmap,
  enableContextMenu: true,
  dispatchAutoRefresh: false
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload
    },
    setEnableContextMenu: (state, action: PayloadAction<boolean>) => {
      state.enableContextMenu = action.payload
    },
    setDispatchAutoRefresh: (state, action: PayloadAction<boolean>) => {
      state.dispatchAutoRefresh = action.payload
    },
    setMapType: (state, action: PayloadAction<MapType>) => {
      state.mapType = action.payload
    }
  }
})

export const {
  setTheme,
  setEnableContextMenu,
  setDispatchAutoRefresh,
  setMapType
} = settingsSlice.actions
export default settingsSlice.reducer
