import { Button } from '@/components/ui/button'
import TextInput from '@/components/ui/inputs/TextInput'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import Spinner from '@/components/ui/Spinner'
import { useReports } from '@/pages/ReportsPage/hooks/useReports'
import { FC, useState } from 'react'
import { TbCirclePlus } from 'react-icons/tb'
import DriversReportDialog from './DriversReportDialog'
import ReportsTable from './ReportsTable'
import RidersReportDialog from './RidersReportDialog'
import TripsReportDialog from './TripsReportDialog'

const ReportsPage: FC = () => {
  const reports = useReports(false)

  const [nameFilter, setNameFilter] = useState<string>('')

  return (
    <div className='flex flex-col w-full h-full gap-4'>
      <div className='flex flex-row w-full justify-between place-items-end'>
        <TextInput
          title='Name'
          id='name'
          value={nameFilter}
          placeholder='Filter name...'
          onChange={setNameFilter}
          className='w-40'
        />
        <Popover>
          <PopoverTrigger asChild>
            <Button className='h-8 w-max'>
              <TbCirclePlus className='h-4 w-4 mr-2' />
              Create Report
            </Button>
          </PopoverTrigger>
          <PopoverContent
            align='end'
            className='flex flex-col gap-0 w-40 [&>*]:w-full [&>*]:h-8 [&>*]:justify-start [&>*]:pl-2 mt-1 p-1'
          >
            <RidersReportDialog />
            <DriversReportDialog />
            <TripsReportDialog />
          </PopoverContent>
        </Popover>
      </div>
      {reports.data ? (
        <ReportsTable
          columnFilters={[
            {
              id: 'name',
              value: nameFilter
            }
          ]}
          data={reports.data}
        />
      ) : (
        <Spinner />
      )}
    </div>
  )
}
export default ReportsPage

