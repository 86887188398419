import { Badge } from '@/components/ui/badge'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card'
import { formatAddress, formatName } from '@/lib/utils'
import { IDriver } from '@/types/drivers.type'
import { FC } from 'react'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { Link } from 'react-router-dom'
import ProfilePicture from '../profile-picture'

interface IDriverPreviewProps {
  value: IDriver
  fullName?: boolean
}

const DriverPreview: FC<IDriverPreviewProps> = (props: IDriverPreviewProps) => {
  return (
    <HoverCard>
      <HoverCardTrigger className='w-max' asChild>
        <span>{formatName(props.value.user, props.fullName ?? false)}</span>
      </HoverCardTrigger>
      <HoverCardContent className='flex flex-col gap-4 min-w-max'>
        <div className='flex flex-row place-items-center gap-4'>
          {props.value.user && <ProfilePicture user={props.value.user} />}
          <Link to={`/drivers/${props.value.id}`}>
            {formatName(props.value.user)} ({props.value.user.username})
          </Link>
          <Badge className='h-6 whitespace-nowrap'>Works Today</Badge>
        </div>
        {props.value.address && (
          <span className='text-sm text-muted-foreground wrap'>
            Address: {formatAddress(props.value.address)}
          </span>
        )}
        {props.value.user.email && (
          <span className='text-sm text-muted-foreground'>
            Email: {props.value.user.email}
          </span>
        )}
        {props.value.user.phone && (
          <span className='text-sm text-muted-foreground'>
            Phone:{' '}
            {formatPhoneNumberIntl(props.value.user.phone) ||
              props.value.user.phone}
          </span>
        )}
        {props.value.notes!?.length > 0 && (
          <span className='text-sm text-muted-foreground'>
            Note(s): {props.value.notes}
          </span>
        )}
      </HoverCardContent>
    </HoverCard>
  )
}
export default DriverPreview
