import tripsService from '@/services/trips.service'
import { useQuery } from '@tanstack/react-query'

export const useTrips = (
  start: Date,
  end: Date,
  refetchInterval: number | undefined = undefined,
  refetchOnWindowFocus = true
) =>
  useQuery({
    queryKey: ['trips', start, end],
    queryFn: async () =>
      await tripsService.getByTime({
        start: start,
        end: end
      }),
    select: response => response.data,
    refetchInterval: refetchInterval,
    refetchOnWindowFocus: refetchOnWindowFocus
  })
