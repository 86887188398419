import { ISchemaUser, IUser } from '@/types/users.type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  user?: IUser
}

const initialState: UserState = {
  user: undefined
}

const userSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    resetUser: () => initialState,
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload
    }
  }
})

export const { resetUser, setUser } = userSlice.actions
export default userSlice.reducer
