import { Button } from '@/components/ui/button'
import DateInput from '@/components/ui/inputs/dateInput/DateInput'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import Spinner from '@/components/ui/Spinner'
import { useShifts } from '@/hooks/queries/useShifts'
import { ITimeRange } from '@/types/common.type'
import { endOfDay, endOfToday, startOfDay, startOfToday } from 'date-fns'
import { FC, useState } from 'react'
import { TbCalendarWeek, TbCirclePlus, TbClock, TbPlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import ShiftsTable from './ShiftsTable'

const ShiftsPage: FC = () => {
  const navigate = useNavigate()

  const [timeFilter, setTimeFilter] = useState<ITimeRange>({
    start: startOfToday(),
    end: endOfToday()
  })

  const shifts = useShifts(timeFilter, false)

  return (
    <div className='flex flex-col gap-4 h-full w-full'>
      <div className='flex flex-row gap-4 w-full justify-between place-items-end'>
        <div className='flex flex-row gap-2'>
          <DateInput
            value={timeFilter.start}
            type='date'
            id='statringFrom'
            title='Starting from'
            className='w-40'
            onChange={async value => {
              setTimeFilter({
                start: startOfDay(value),
                end: endOfDay(value)
              })
            }}
          />
          <DateInput
            value={timeFilter.end}
            type='date'
            id='endingAt'
            title='Ending at'
            className='w-40'
            onChange={async value => {
              if (value < timeFilter.start)
                return setTimeFilter({
                  start: startOfDay(value),
                  end: endOfDay(value)
                })

              setTimeFilter({ ...timeFilter, end: endOfDay(value) })
            }}
          />
        </div>
        <div className='flex flex-row gap-2'>
          <Popover>
            <PopoverTrigger>
              <Button className='h-8'>
                <TbCirclePlus className='h-4 w-4 mr-2' />
                Create Shift
              </Button>
            </PopoverTrigger>
            <PopoverContent
              align='end'
              className='flex flex-col gap-0 w-40 [&>*]:w-full [&>*]:h-8 [&>*]:justify-start [&>*]:pl-2 mt-1 p-1'
            >
              <Button variant='ghost' onClick={() => navigate('/shifts/create')}>
                <TbPlus className='min-h-4 min-w-4 mr-2' />
                Single Shift
              </Button>
              <Button variant='ghost' onClick={() => navigate('/shifts/recurring/create')}>
                <TbCalendarWeek className='min-h-4 min-w-4 mr-2' />
                Recurring Shift
              </Button>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      {shifts.data && !shifts.isLoading ? (
        <ShiftsTable data={shifts.data} />
      ) : (
        <Spinner />
      )}
    </div>
  )
}
export default ShiftsPage
