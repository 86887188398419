import AddressesCell from '@/components/tables/TripsTable/AdrdessesCell'
import ScheduleContextMenu from '@/components/tables/TripsTable/ScheduleContextMenu'
import ScheduleStatusBadge from '@/components/tables/TripsTable/ScheduleStatusBadge'
import TripActions from '@/components/tables/TripsTable/TripActions'
import {
  sortTripAddresses,
  sortTripPlain,
  sortTripRiders,
  sortTripTimes
} from '@/components/tables/TripsTable/tripUtils'
import UnassignDialog from '@/components/tables/TripsTable/UnassignDialog'
import RiderPreview from '@/components/ui/previews/RiderPreview'
import TimePreview from '@/components/ui/previews/TimePreview'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { useToast } from '@/components/ui/use-toast'
import { isTripActiveOrComplete } from '@/lib/utils'
import { ISchedule, ITrip } from '@/types/trips.type'
import { Row } from '@tanstack/react-table'
import { FC, useState } from 'react'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'

interface IDispatchPageScheduleTableProps
  extends ICustomizableTableProps<ITrip> {}

const DispatchPageUnassignedScheduleTable: FC<
  IDispatchPageScheduleTableProps
> = (props: IDispatchPageScheduleTableProps) => {
  const { toast } = useToast()

  const [unassignableTrip, setUnassignableTrip] = useState<ITrip>()

  return (
    <>
      <UnassignDialog
        unassignableTrip={unassignableTrip}
        setUnassignableTrip={setUnassignableTrip}
      />
      <Table
        {...props}
        disablePagination
        getSubRowsFn={trip => trip.schedules}
        contextMenu={(schedule: ISchedule, row: Row<any>) => (
          <ScheduleContextMenu
            current={schedule}
            parent={row.getParentRow()?.original}
          />
        )}
        dnd={{
          draggable: true,
          droppable: 'table',
          deps: [props.data],
          type: 'trip',
          canDrop: (item: ITrip) => {
            if (!item.shift) return false
            if (isTripActiveOrComplete(item)) return false

            return true
          },
          onDrop: (item: ITrip) => {
            return setUnassignableTrip(item)
          }
        }}
        initialState={{
          sorting: [
            {
              id: 'pickupTime',
              desc: false
            }
          ],
          pagination: {
            pageSize: 16
          }
        }}
        columns={[
          {
            header: '#',
            accessorKey: 'expander',
            enableSorting: false,
            accessorFn: data => data.status,
            cell: props => (
              <div>
                {props.row.getCanExpand() && (
                  <button
                    onClick={props.row.getToggleExpandedHandler()}
                    style={{ cursor: 'pointer' }}
                  >
                    {props.row.getIsExpanded() ? (
                      <TbChevronUp />
                    ) : (
                      <TbChevronDown />
                    )}
                  </button>
                )}
                {props.getValue() && (
                  <ScheduleStatusBadge value={props.getValue()} />
                )}
              </div>
            )
          },
          {
            accessorKey: 'rider',
            header: 'Rider',
            accessorFn: data => data.rider,
            sortingFn: sortTripRiders,
            cell: props =>
              props.getValue() ? (
                <RiderPreview value={props.getValue()} />
              ) : (
                <span>{props.row.original.schedules.length} riders</span>
              )
          },
          {
            accessorKey: 'addresses',
            header: 'From & To',
            accessorFn: data => data,
            sortingFn: sortTripAddresses,
            cell: props => <AddressesCell {...props} />
          },
          {
            accessorKey: 'pickupTime',
            header: 'Pickup Time',
            accessorFn: data => data,
            sortingFn: sortTripTimes,
            cell: props => (
              <TimePreview value={props.getValue()} time='pickup' />
            )
          },
          {
            accessorKey: 'dropoffTime',
            header: 'Dropoff Time',
            accessorFn: data => data,
            sortingFn: sortTripTimes,
            cell: props => (
              <TimePreview value={props.getValue()} time='dropoff' />
            )
          },
          {
            accessorKey: 'mobility',
            header: 'Mobility',
            accessorFn: data => data?.mobility,
            sortingFn: sortTripPlain,
            cell: props => <span>{props.getValue()}</span>
          },
          {
            id: 'actions',
            size: 50,
            cell: props => <TripActions {...props} />
          }
        ]}
      />
    </>
  )
}
export default DispatchPageUnassignedScheduleTable
