import { formatName } from '@/lib/utils'
import { IRider } from '@/types/riders.type'
import { Badge } from 'lucide-react'
import { FC } from 'react'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { Link } from 'react-router-dom'
import { Avatar, AvatarFallback } from '../avatar'
import { HoverCardContent } from '../hover-card'

interface IRiderPreviewContentProps {
  value: IRider
}

const RiderPreviewContent: FC<IRiderPreviewContentProps> = (
  props: IRiderPreviewContentProps
) => {
  return (
    <HoverCardContent className='flex flex-col gap-4 min-w-max'>
      <div className='flex flex-row place-items-center gap-4'>
        <Avatar>
          <AvatarFallback>
            {props.value.firstName[0].toUpperCase()}
            {props.value.lastName[0].toUpperCase()}
          </AvatarFallback>
        </Avatar>
        <Link to={`/riders/${props.value.id}`}>{formatName(props.value)}</Link>
        {props.value.gender && (
          <Badge className='h-max'>{String(props.value.gender)}</Badge>
        )}
      </div>
      {props.value.email && (
        <span className='text-sm text-muted-foreground'>
          Email: {props.value.email}
        </span>
      )}
      {props.value.language && (
        <span className='text-sm text-muted-foreground'>
          Language: {props.value.language}
        </span>
      )}
      {props.value.phone && (
        <span className='text-sm text-muted-foreground'>
          Phone: {formatPhoneNumberIntl(props.value.phone) || props.value.phone}
        </span>
      )}
      {props.value.notes && (
        <span className='text-sm text-muted-foreground'>
          Notes: {props.value.notes}
        </span>
      )}
    </HoverCardContent>
  )
}
export default RiderPreviewContent

