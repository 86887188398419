import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import MailChangeDialog from '@/components/ui/MailChangeDialog'
import PasswordRecoveryDialog from '@/components/ui/PasswordRecoveryDialog'
import { PhoneInput } from '@/components/ui/phone-input'
import { Separator } from '@/components/ui/separator'
import { ISchemaUser, IUser, userSchema } from '@/types/users.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TbPassword } from 'react-icons/tb'
import TabHeader from '../../components/tab-header'
import { useUpdateMe } from '../../hooks/useUpdateMe'

interface IAccountTabProps {
  me: IUser
}

const AccountTab: FC<IAccountTabProps> = (props: IAccountTabProps) => {
  const form = useForm<
    Pick<ISchemaUser, 'username' | 'phone' | 'email' | 'password'>
  >({
    resolver: yupResolver(
      userSchema.pick(['username', 'phone', 'email', 'password'])
    )
  })

  const { mutate: submit } = useUpdateMe()

  useEffect(() => form.reset(props.me), [props.me])

  return (
    <Form {...form}>
      <form
        className='flex flex-col gap-6'
        onSubmit={form.handleSubmit(data => submit(data as IUser))}
      >
        <TabHeader
          title='Account'
          description='Update your account and security settings here.'
        />
        <Separator />
        <div className='flex flex-col gap-4 w-[600px]'>
          <FormField
            control={form.control}
            name='username'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username *</FormLabel>
                <FormControl>
                  <Input className='max-w-full h-8' {...field} />
                </FormControl>
                <FormDescription>
                  Your username is what you are using to log in.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='phone'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone Number</FormLabel>
                <FormControl>
                  <PhoneInput
                    className='max-w-full'
                    defaultCountry='US'
                    {...field}
                  />
                </FormControl>
                <FormDescription></FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email *</FormLabel>
                <FormControl>
                  <div className='flex flex-row gap-2 max-w-full'>
                    <Input disabled className='max-w-full h-8' {...field} />
                    <MailChangeDialog id='me' />
                  </div>
                </FormControl>
                <FormDescription>
                  Your email address. It's used for account notifications and
                  password recovery.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='password'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <div className='flex flex-row gap-2 max-w-full'>
                    <Input
                      type='password'
                      disabled
                      className='max-w-full h-8'
                      {...field}
                      value='*************'
                    />
                    <PasswordRecoveryDialog mail={props.me.email} />
                  </div>
                </FormControl>
                <FormDescription>
                  Your account password. Press <b>Change</b> to update it, using
                  unique and strong passwords is recommended.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button className='w-max h-8' type='submit'>
          <TbPassword className='h-4 w-4 mr-2' />
          Update Account
        </Button>
      </form>
    </Form>
  )
}
export default AccountTab
