import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { IImportTaskError } from '@/types/import.type'
import { FC } from 'react'
import { TbThumbUp } from 'react-icons/tb'

interface IImportErrorsTableProps
  extends ICustomizableTableProps<IImportTaskError> {}

const ImportErrorsTable: FC<IImportErrorsTableProps> = (
  props: IImportErrorsTableProps
) => {
  return (
    <Table
      {...props}
      disablePagination
      emptyElement={
        <div className='flex flex-col gap-2 place-items-center justify-center text-center'>
          <TbThumbUp className='h-8 w-8' />
          <h3 className='font-semibold text-lg'>No errors were found</h3>
          <span className='text-sm text-muted-foreground'>
            There are no failed rows that weren't imported.
          </span>
        </div>
      }
      columns={[
        {
          header: 'Index',
          accessorKey: 'index',
          size: 20,
          cell: props => <span>{props.getValue()}</span>
        },
        {
          header: 'Row',
          accessorKey: 'row',
          enableSorting: false,
          cell: props => <span className='text-xs'>{props.getValue()}</span>
        },
        {
          header: 'Error message',
          accessorKey: 'message',
          enableSorting: false,
          cell: props => <span>{props.getValue()}</span>
        }
      ]}
    />
  )
}
export default ImportErrorsTable

