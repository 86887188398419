import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { DialogProps } from '@radix-ui/react-dialog'
import { FC, useContext } from 'react'
import { TbCheck, TbTable } from 'react-icons/tb'
import ImportContext from '../ImportContext'
import ImportPropertiesHeader from './ImportPropertiesHeader'
import PresetToolbox from './PresetToolbox'

const ImportProperties: FC<DialogProps> = (props: DialogProps) => {
  const context = useContext(ImportContext)

  return (
    <Dialog {...props}>
      <DialogTrigger asChild>
        <Button
          variant='outline'
          title={`Click to setup header properties. Currently ${context.isAvailableForImport ? 'all' : 'not all'} fields are set and you ${context.isAvailableForImport ? 'can' : 'cannot'} continue.`}
          className='w-full border-2 border-dashed'
        >
          Setup Properties
          {context.isAvailableForImport ? (
            <TbCheck className='ml-2' />
          ) : (
            <TbTable className='ml-2' />
          )}
        </Button>
      </DialogTrigger>
      <DialogContent className='min-w-[90%] min-h-[90%] max-h-[90%] flex flex-col place-items-top overflow-y-auto'>
        <DialogHeader>
          <DialogTitle>Properties</DialogTitle>
          <DialogDescription>
            Set-up Properties to make each ManageNEMT value correspond with
            right headers from your file.
          </DialogDescription>
        </DialogHeader>
        <PresetToolbox />
        <div className='flex flex-col w-full h-full gap-4 place-items-center'>
          <ImportPropertiesHeader
            name='First Name'
            description="Rider's First Name"
            property='firstName'
            required
          />
          <ImportPropertiesHeader
            name='Last Name'
            description="Rider's Last Name"
            property='lastName'
            required
          />
          <ImportPropertiesHeader
            name='Phone'
            description="Rider's Phone number"
            property='phone'
            required
          />
          <ImportPropertiesHeader name='From' property='from' required />
          <ImportPropertiesHeader name='To' property='to' required />
          <ImportPropertiesHeader
            name='Guests'
            description='Amount of guests'
            property='guests'
          />
          <ImportPropertiesHeader
            name='Comments'
            property='comments'
            required
          />
          <ImportPropertiesHeader
            name='Mobility'
            description='Field containing Wheelchair (WC) / Lift / Stretcher / Ambulatory'
            property='mobility'
            required
          />
          <ImportPropertiesHeader name='Date' property='date' required />
          <ImportPropertiesHeader
            name='Pickup Time'
            property='pickupTime'
            required
          />
          <ImportPropertiesHeader
            name='Dropoff Time'
            property='dropoffTime'
            required
          />
          <ImportPropertiesHeader
            name='Trip Number'
            description='External trip number, used for duplicate trip detection.'
            property='tripNumber'
          />
          <ImportPropertiesHeader
            name='Status'
            description='Status of trip'
            property='status'
          />
          <ImportPropertiesHeader
            name='Date Of Birth'
            description="Rider's Date of Birth"
            property='dateOfBirth'
          />
          <ImportPropertiesHeader
            name='Weight'
            description="Rider's weight"
            property='weight'
          />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button>Save</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
export default ImportProperties
