import { cn } from '@/lib/utils'
import { FC, useContext } from 'react'
import {
  TbAdjustments,
  TbAnchor,
  TbBuildingSkyscraper,
  TbCalendar,
  TbClock,
  TbLayoutDashboard,
  TbMap,
  TbReport,
  TbSettings,
  TbShirt,
  TbTruck,
  TbUser,
  TbUserDollar,
  TbUserShield
} from 'react-icons/tb'
import { Link } from 'react-router-dom'
import SearchBar from './SearchBar'
import SideBarButton from './SideBarButton'
import SideBarCategory from './SideBarCategory'
import SideBarContext from './SideBarContext'
import Logo from '@/components/ui/logo'

const SideBar: FC = () => {
  const context = useContext(SideBarContext)

  return (
    <aside
      className={cn(
        'flex flex-col h-screen border-r min-w-[300px] p-4 pt-8 gap-4 overflow-hidden overflow-y-auto transition-all',
        context.isCollapsed && 'min-w-[100px]'
      )}
    >
      <div className='flex flex-row justify-between'>
        <Link
          to='/'
          className={cn(
            'flex flex-row w-full gap-2 justify-center place-items-center mb-4',
            context.isCollapsed && 'justify-center'
          )}
        >
          <Logo className='w-8 h-8' />
          <h1 className={cn('font-medium', context.isCollapsed && '!hidden')}>
            ManageNEMT
          </h1>
        </Link>
      </div>
      <SearchBar />
      <SideBarButton icon={TbLayoutDashboard} href='/' title='Dashboard' />
      <SideBarButton icon={TbClock} href='/dispatch' title='Dispatch' />
      <SideBarButton icon={TbMap} href='/live-map' title='Live Map' />
      <SideBarButton icon={TbCalendar} href='/trips' title='Trips' />
      <SideBarButton icon={TbShirt} href='/shifts' title='Shifts' />
      <SideBarButton icon={TbReport} href='/reports' title='Reports' />
      <SideBarCategory icon={TbBuildingSkyscraper} title='Company'>
        <SideBarButton icon={TbTruck} href='/vehicles' title='Vehicles' />
        <SideBarButton icon={TbUser} href='/drivers' title='Drivers' />
        <SideBarButton icon={TbAnchor} href='/riders' title='Riders' />
      </SideBarCategory>
      <SideBarCategory icon={TbSettings} title='Settings'>
        <SideBarButton icon={TbUserDollar} href='/brokers' title='Brokers' />
        <SideBarButton icon={TbUserShield} href='/admins' title='Admins' />
        <SideBarButton icon={TbAdjustments} href='/settings' title='Company' />
      </SideBarCategory>
    </aside>
  )
}

export default SideBar
