import tripsService from '@/services/trips.service'
import { useQuery } from '@tanstack/react-query'

export const useTask = (taskId: string, enabled?: boolean) =>
  useQuery({
    queryFn: async () => await tripsService.getImportStats(taskId),
    enabled: enabled,
    select: response => response?.data,
    refetchInterval: 300,
    queryKey: ['importTask']
  })

