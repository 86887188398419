import { useShifts } from '@/hooks/queries/useShifts'
import { useTrips } from '@/hooks/queries/useTrips'
import { endOfToday, startOfToday } from 'date-fns'
import { FC } from 'react'
import ActiveNow from './elements/ActiveNow'
import CompletedToday from './elements/CompletedToday'
import RidersList from './elements/RidersList'
import ShiftsList from './elements/ShiftsList'
import TodayOverview from './elements/TodayOverview'
import TodayTrips from './elements/TodayTrips'
import WeekOverview from './elements/WeekOverview'

const DashboardPage: FC = () => {
  const trips = useTrips(startOfToday(), endOfToday())
  const shifts = useShifts({
    start: startOfToday(),
    end: endOfToday()
  })

  return (
    <div className='flex flex-col gap-4 w-full h-max'>
      <div className='flex flex-col sm:flex-row w-full h-full sm:h-1/3 gap-4'>
        <TodayOverview className='w-full sm:w-4/5' />
        <div className='flex flex-col gap-2 h-full sm:w-1/5 w-full'>
          <ActiveNow
            trips={trips}
            className='w-full h-2/5 overflow-x-auto overflow-y-hidden'
          />
          <CompletedToday
            trips={trips}
            className='w-full h-3/5 overflow-x-auto overflow-y-hidden'
          />
        </div>
      </div>
      <div className='flex flex-col sm:flex-row w-full h-full gap-4'>
        <div className='flex sm:flex-row flex-col w-full sm:w-3/4 h-full gap-4'>
          <TodayTrips className='w-full sm:w-1/3 overflow-x-auto overflow-y-hidden' />
          <WeekOverview className='w-full sm:w-2/3' />
        </div>
        <div className='flex flex-col w-full sm:w-1/4 h-full gap-4 overflow-y-hidden'>
          <ShiftsList shifts={shifts} trips={trips} />
          <RidersList trips={trips} />
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
