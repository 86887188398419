import { Separator } from '@/components/ui/separator'
import Spinner from '@/components/ui/Spinner'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useMe } from '@/hooks/queries/useMe'
import { TabsContent } from '@radix-ui/react-tabs'
import { FC } from 'react'
import { TbBell, TbBrush, TbPassword, TbUser } from 'react-icons/tb'
import { useSearchParams } from 'react-router-dom'
import AccountTab from './tabs/accountTab/AccountTab'
import AppearanceTab from './tabs/appearanceTab/AppearanceTab'
import NotificationsTab from './tabs/notificationsTab/NotificationsTab'
import PersonalTab from './tabs/personalTab/PersonalTab'

enum Tab {
  Account = 'account',
  Personal = 'personal',
  Notifications = 'notifications',
  Appearance = 'appearance'
}

const MeSettingsPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    tab: Tab.Account
  })

  const me = useMe()

  return (
    <div className='flex flex-col gap-4 w-full h-full'>
      <div className='flex flex-col gap-1'>
        <h1 className='text-2xl font-semibold'>Preferences</h1>
        <h2 className='text-muted-foreground'>
          Manage your account settings and appearance preferences here.
        </h2>
        <Separator className='mt-6' />
      </div>
      <Tabs
        defaultValue={searchParams.get('tab') || Tab.Account}
        className='flex flex-row gap-4'
      >
        <TabsList className='flex flex-col h-full justify-start bg-background *:w-full *:justify-start [&>*[data-state=active]]:bg-accent *:h-10 *:rounded-md w-64 gap-2'>
          <TabsTrigger
            value={Tab.Account}
            onClick={() => setSearchParams({ tab: Tab.Account })}
          >
            <TbPassword className='h-4 w-4 mr-2' />
            <span>Account</span>
          </TabsTrigger>
          <TabsTrigger
            value={Tab.Personal}
            onClick={() => setSearchParams({ tab: Tab.Personal })}
          >
            <TbUser className='h-4 w-4 mr-2' />
            <span>Personal</span>
          </TabsTrigger>
          <TabsTrigger
            value={Tab.Notifications}
            onClick={() => setSearchParams({ tab: Tab.Notifications })}
          >
            <TbBell className='h-4 w-4 mr-2' />
            <span>Notifications</span>
          </TabsTrigger>
          <TabsTrigger
            value={Tab.Appearance}
            onClick={() => setSearchParams({ tab: Tab.Appearance })}
          >
            <TbBrush className='h-4 w-4 mr-2' />
            <span>Appearance</span>
          </TabsTrigger>
        </TabsList>
        <TabsContent value={Tab.Account}>
          {me.data ? <AccountTab me={me.data} /> : <Spinner />}
        </TabsContent>
        <TabsContent value={Tab.Personal}>
          {me.data ? <PersonalTab me={me.data} /> : <Spinner />}
        </TabsContent>
        <TabsContent value={Tab.Notifications}>
          {me.data ? <NotificationsTab me={me.data} /> : <Spinner />}
        </TabsContent>
        <TabsContent value={Tab.Appearance}>
          <AppearanceTab />
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default MeSettingsPage
