import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Progress } from '@/components/ui/progress'
import Spinner from '@/components/ui/Spinner'
import { DialogProps } from '@radix-ui/react-dialog'
import { FC, useContext, useEffect, useState } from 'react'
import { TbCheck } from 'react-icons/tb'
import { useStartImport } from '../hooks/useStartImport'
import { useTask } from '../hooks/useTask'
import ImportContext from './ImportContext'
import ImportErrorsTable from './ImportErrorsTable'

export interface IImportTaskProps extends DialogProps {
  disabled?: boolean
}

const ImportTask: FC<IImportTaskProps> = (props: IImportTaskProps) => {
  const context = useContext(ImportContext)

  const [taskId, setTaskId] = useState<string>('')
  const [isImporting, setIsImporting] = useState<boolean>(false)

  const startImportMutation = useStartImport()
  const task = useTask(taskId, isImporting)

  const startImport = () => {
    startImportMutation.mutate(context.form.getValues(), {
      onSuccess: ({ data }) => {
        setTaskId(data)
      }
    })
  }

  useEffect(() => {
    setIsImporting(
      task.data ? !task.data.finished : startImportMutation.isSuccess
    )
  }, [task.data, startImportMutation.isSuccess])

  return (
    <>
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button disabled={props.disabled}>Import</Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              You want to import{' '}
              {context.form.watch('data')?.split('\n').length} lines with
              {context.form.watch('autoSchedule') ? '' : 'out'} autoscheduling
              {!context.form.watch('autoSchedule') &&
                ' (you can autoschedule later)'}
              .
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={startImport}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog open={!!taskId} {...props}>
        <AlertDialogContent className='flex flex-col min-w-[50%] w-[50%] min-h-[60%] h-[60%]'>
          <AlertDialogHeader>
            <AlertDialogTitle>Importing...</AlertDialogTitle>
            <AlertDialogDescription>
              Your file is currently being imported. This may take a few
              moments, please be patient as we process your data. Once the
              import is complete, press "Continue" button to finish.
              <br />
              Task ID: {taskId}
            </AlertDialogDescription>
          </AlertDialogHeader>
          {task.data && (
            <>
              <div className='flex flex-col gap-1 place-items-center w-full'>
                <div className='flex flex-row gap-1 place-items-center'>
                  {task.data.finished ? (
                    <TbCheck className='h-4 w-4' />
                  ) : (
                    <Spinner className='h-4 w-4' />
                  )}
                  <span className='text-sm'>
                    {task.data.imported}/{task.data.total} rows were processed (
                    {task.data.errors.length} failed).
                  </span>
                </div>
                <Progress
                  className='w-96'
                  value={(task.data.imported / task.data.total) * 100}
                />
              </div>
              <ImportErrorsTable
                className='overflow-y-auto'
                data={task.data.errors}
              />
              <AlertDialogFooter className='mt-auto'>
                <AlertDialogAction
                  onClick={() => window.location.reload()}
                  disabled={!task.data.finished}
                >
                  Continue
                </AlertDialogAction>
              </AlertDialogFooter>
            </>
          )}
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
export default ImportTask
