import reportsService from '@/services/reports.service'
import { useQuery } from '@tanstack/react-query'

export const useReports = (refetchOnWindowFocus = true) =>
  useQuery({
    queryKey: ['reports'],
    queryFn: async () => await reportsService.getAll(),
    refetchInterval: 3000,
    select: response => response.data,
    refetchOnWindowFocus: refetchOnWindowFocus
  })

