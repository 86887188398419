import { IBroker } from './brokers.type'

export enum HeaderType {
  Split = 'Split',
  Add = 'Add',
  Each = 'Each',
  Get = 'Get'
}

export enum ImportRowStatus {
  Importing = 'Importing',
  Success = 'Success',
  Failed = 'Failed'
}

export interface IImportProperty {
  headers: string[]
  type?: HeaderType
  separator?: string
  select?: number
}

export interface IImportTaskError {
  index: number
  row: string
  message: string
}

export interface IImportTask {
  finished: boolean
  imported: number
  total: number
  errors: IImportTaskError[]
}

export interface IImportPreset extends IImportProperties {
  isDefault: boolean
}

export interface IImportProperties {
  firstName: IImportProperty
  lastName: IImportProperty
  phone: IImportProperty
  from: IImportProperty
  to: IImportProperty
  guests: IImportProperty
  comments: IImportProperty
  mobility: IImportProperty
  date: IImportProperty
  pickupTime: IImportProperty
  dropoffTime: IImportProperty
  tripNumber: IImportProperty
  dateOfBirth: IImportProperty
  status: IImportProperty
  weight: IImportProperty
}

export interface IImport {
  data: string
  properties: IImportProperties
  autoSchedule?: boolean
  broker: IBroker
  brokerId: number
}
