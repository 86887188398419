import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { isTripActive } from '@/lib/utils'
import { ITrip } from '@/types/trips.type'
import { UseQueryResult } from '@tanstack/react-query'
import { FC, HTMLAttributes, useMemo } from 'react'
import { TbActivity } from 'react-icons/tb'

interface ICompletedTodayProps extends HTMLAttributes<HTMLDivElement> {
  trips: UseQueryResult<ITrip[]>
}

const ActiveNow: FC<ICompletedTodayProps> = (props: ICompletedTodayProps) => {
  const activeNow = useMemo(
    () => (props.trips.data ? props.trips.data.filter(isTripActive).length : 0),
    [props.trips.data]
  )

  return (
    <Card {...props}>
      <CardHeader>
        <CardTitle className='flex flex-row gap-2'>
          Active Now <TbActivity />
        </CardTitle>
        <CardDescription>
          {props.trips.data ? (
            <span>
              <strong>{activeNow}</strong> trips are active now.
            </span>
          ) : (
            <>
              <Skeleton className='w-full h-6 mt-4' />
              <Skeleton className='w-full rounded-full h-4 mt-4' />
            </>
          )}
        </CardDescription>
      </CardHeader>
    </Card>
  )
}
export default ActiveNow

