import { HoverCard, HoverCardTrigger } from '@/components/ui/hover-card'
import RiderPreviewContent from '@/components/ui/previews/RiderPreviewContent'
import Spinner from '@/components/ui/Spinner'
import { useRider } from '@/hooks/queries/useRider'
import { ISchemaSchedule } from '@/types/trips.type'
import { FC } from 'react'
import { TbAnchor } from 'react-icons/tb'

interface IScheduleRiderPreviewProps {
  schedule: ISchemaSchedule
}

const ScheduleRiderPreview: FC<IScheduleRiderPreviewProps> = (
  props: IScheduleRiderPreviewProps
) => {
  const rider = useRider(props.schedule.riderId)

  if (!props.schedule.riderId) return <></>

  return rider.data ? (
    <HoverCard>
      <HoverCardTrigger>
        <TbAnchor className='h-6 w-6' />
      </HoverCardTrigger>
      <RiderPreviewContent value={rider.data} />
    </HoverCard>
  ) : (
    <Spinner />
  )
}
export default ScheduleRiderPreview

