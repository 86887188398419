import { toast } from '@/components/ui/use-toast'
import usersService from '@/services/users.service'
import { IAPIError } from '@/types/common.type'
import { useMutation } from '@tanstack/react-query'

export const useReinvite = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      return await usersService.reinvite(id)
    },
    onSuccess: () => {
      toast({
        title: 'Success!',
        description: `A new invitation link was sent to user's email.`
      })
    },
    onError: (error: IAPIError) => {
    toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
