import {
  TableCell as PrimitiveTableCell,
  TableRow as PrimitiveTableRow
} from '@/components/ui/table'
import { cn } from '@/lib/utils'
import { flexRender, Row } from '@tanstack/react-table'
import { FC, useCallback } from 'react'
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop
} from 'react-dnd'
import { ITableDND } from './Table'

interface ITableRowProps<T> {
  row: Row<T>
  dnd?: ITableDND<T>
  onContextMenu?: React.MouseEventHandler<HTMLTableRowElement>
  onSelectionChange?: (data: T | undefined, row?: Row<T>) => any
}

const TableRow: FC<ITableRowProps<any>> = <T,>(props: ITableRowProps<T>) => {
  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: typeof props.dnd?.type,
      drop: (item: T) => {
        props.dnd?.onDrop?.(item, props.row.original)
        return item
      },
      canDrop: props.dnd?.canDrop,
      collect: (monitor: DropTargetMonitor<unknown>) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    props.dnd?.deps
  )

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: typeof props.dnd?.type,
      item: props.row.getParentRow()
        ? props.row.getParentRow()?.original
        : props.row.original,
      options: {
        dropEffect: 'copy',
        ...props.dnd?.deps
      },
      collect: (monitor: DragSourceMonitor<unknown, unknown>) => ({
        isDragging: monitor.isDragging()
      })
    }),
    props.dnd?.deps
  )

  const dndRef = useCallback(
    (node: HTMLTableRowElement | null) => {
      const { draggable, droppable } = props.dnd || {}

      /* Breaking change, if everything works as expected, remove this */
      /* if (draggable && droppable === 'row') return dragRef(dropRef(node)) */
      if (droppable === 'row') dropRef(node)
      if (draggable) dragRef(node)
    },
    [props.dnd]
  )

  return (
    <PrimitiveTableRow
      data-state={props.row.getIsSelected() && 'selected'}
      ref={dndRef}
      onContextMenu={props.onContextMenu}
      className={cn(
        isDragging && 'opacity-40',
        isOver && canDrop && '!bg-foreground/20',
        props.row.subRows.length === 1 && 'hidden',
        (props.row.subRows.length > 1 ||
          props.row.getParentRow()!?.subRows.length > 1) &&
          'border-l-4 border-l-affirmative'
      )}
      key={`row-${props.row.id}`}
    >
      {props.row.getVisibleCells().map(cell => (
        <PrimitiveTableCell
          onClick={() => {
            props.row.toggleSelected(!props.row.getIsSelected())
            props.onSelectionChange?.(
              props.row.getIsSelected() ? undefined : props.row.original,
              props.row.getIsSelected() ? undefined : props.row
            )
          }}
          key={`cell-${cell.id}`}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </PrimitiveTableCell>
      ))}
    </PrimitiveTableRow>
  )
}
export default TableRow
