import { useToast } from '@/components/ui/use-toast'
import usersService from '@/services/users.service'
import { IAPIError } from '@/types/common.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useUploadProfilePicture = () => {
  const { toast } = useToast()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, file }: { id: number | 'me'; file: File }) => {
      await usersService.uploadProfilePicture(id, file)
      return id
    },
    onSuccess: async id => {
      toast({
        title: 'Success!',
        description: 'Profile picture was successfully uploaded.'
      })
      await queryClient.invalidateQueries({
        queryKey: ['profilePicture', id]
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}
