import AddressesCell from '@/components/tables/TripsTable/AdrdessesCell'
import ScheduleContextMenu from '@/components/tables/TripsTable/ScheduleContextMenu'
import ScheduleStatusBadge from '@/components/tables/TripsTable/ScheduleStatusBadge'
import TripActions from '@/components/tables/TripsTable/TripActions'
import {
  sortTripAddresses,
  sortTripPlain,
  sortTripRiders,
  sortTripTimes
} from '@/components/tables/TripsTable/tripUtils'
import RiderPreview from '@/components/ui/previews/RiderPreview'
import TimePreview from '@/components/ui/previews/TimePreview'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { useAssignTrip } from '@/hooks/mutations/useAssignTrip'
import { ISchedule, ITrip } from '@/types/trips.type'
import { Row } from '@tanstack/react-table'
import { FC, useContext } from 'react'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'
import DispatchPageContext from '../DispatchPageContext'
import { isTripActiveOrComplete } from '@/lib/utils'

interface IDispatchPageScheduleTableProps
  extends ICustomizableTableProps<ITrip> {}

const DispatchPageAssignedScheduleTable: FC<IDispatchPageScheduleTableProps> = (
  props: IDispatchPageScheduleTableProps
) => {
  const context = useContext(DispatchPageContext)

  const { mutate: assignTrip } = useAssignTrip()

  return (
    <Table
      {...props}
      getSubRowsFn={trip => trip.schedules}
      disablePagination
      contextMenu={(schedule: ISchedule, row: Row<any>) => (
        <ScheduleContextMenu
          current={schedule}
          parent={row.getParentRow()?.original}
        />
      )}
      dnd={{
        draggable: true,
        droppable: context.selectedShift && 'table',
        type: 'trip',
        deps: [context.selectedShift, props.data],
        canDrop: (item: ITrip) => {
          if (!context.selectedShift) return false
          if (item.shift) return false

          return true
        },
        onDrop: (item: ITrip) => {
          assignTrip({
            shift: context.selectedShift!,
            trip: item
          })
        }
      }}
      initialState={{
        sorting: [
          {
            id: 'pickupTime',
            desc: false
          }
        ]
      }}
      columns={[
        {
          header: '#',
          accessorKey: 'expander',
          enableSorting: false,
          accessorFn: data => data.status,
          cell: props => (
            <div>
              {props.row.getCanExpand() && (
                <button
                  onClick={props.row.getToggleExpandedHandler()}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  {props.row.getIsExpanded() ? (
                    <TbChevronUp />
                  ) : (
                    <TbChevronDown />
                  )}
                </button>
              )}
              {props.getValue() && (
                <ScheduleStatusBadge value={props.getValue()} />
              )}
            </div>
          )
        },
        {
          accessorKey: 'rider',
          header: 'Rider',
          accessorFn: data => data.rider,
          sortingFn: sortTripRiders,
          cell: props =>
            props.getValue() ? (
              <RiderPreview value={props.getValue()} />
            ) : (
              <span>{props.row.original.schedules.length} riders</span>
            )
        },
        {
          accessorKey: 'addresses',
          header: 'From & To',
          accessorFn: data => data,
          sortingFn: sortTripAddresses,
          cell: props => <AddressesCell {...props} />
        },
        {
          accessorKey: 'pickupTime',
          header: 'PU Time',
          accessorFn: data => data,
          sortingFn: sortTripTimes,
          cell: props => <TimePreview value={props.getValue()} time='pickup' />
        },
        {
          accessorKey: 'dropoffTime',
          header: 'DO Time',
          accessorFn: data => data,
          sortingFn: sortTripTimes,
          cell: props => <TimePreview value={props.getValue()} time='dropoff' />
        },
        {
          accessorKey: 'mobility',
          header: 'Mobility',
          accessorFn: data => data?.mobility,
          sortingFn: sortTripPlain,
          cell: props => <span>{props.getValue()}</span>
        },
        {
          id: 'actions',
          size: 50,
          cell: props => <TripActions {...props} />
        }
      ]}
    />
  )
}
export default DispatchPageAssignedScheduleTable
