import authService from '@/services/auth.service'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const useLogout = () => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async () => {
      return await authService.logout()
    },
    onSettled: () => {
      window.localStorage.removeItem('accessToken')
      window.localStorage.removeItem('refreshToken')

      navigate('/login')
    }
  })
}

