import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { useState } from 'react'
import { TbCheck, TbSelector } from 'react-icons/tb'

interface IEnumInputProps<T> {
  title?: string
  disabled?: boolean
  required?: boolean
  enum: T
  value?: keyof T | undefined
  id?: string
  fieldClassName?: string
  popoverClassName?: string
  onChange?: (value: T[keyof T] | undefined) => any
}

const EnumInput = <T,>(props: IEnumInputProps<T>) => {
  const [open, setOpen] = useState<boolean>()

  return (
    <div className='flex flex-col gap-2'>
      {props.title && (
        <Label htmlFor={props.id}>
          {props.title}
          {props.required && ' *'}
        </Label>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger disabled={props.disabled} asChild>
          <Button
            variant='outline'
            role='combobox'
            id={props.id}
            className={cn(`h-8 w-64 justify-between`, props.fieldClassName)}
            aria-expanded={open}
          >
            {props.value
              ? (props.value as string)
                  .replace(/([a-z])([A-Z])/g, '$1 $2')
                  .trim()
              : 'Select...'}
            <TbSelector />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn('w-64 p-0', props.popoverClassName)}>
          <Command>
            <CommandInput placeholder='Search...' />
            <CommandList>
              <CommandEmpty>No results.</CommandEmpty>
              <CommandGroup>
                {!props.required && (
                  <CommandItem
                    key={'unselected'}
                    value='unselected'
                    onSelect={select => {
                      props.onChange?.(undefined)
                      setOpen(false)
                    }}
                  >
                    <TbCheck
                      className={`w-4 h-4 mr-2 ${props.value === '' ? 'opacity-100' : 'opacity-0'}`}
                    />
                    Select...
                  </CommandItem>
                )}
                {Object.keys(props.enum as any).map(value => {
                  return (
                    <CommandItem
                      key={String(value)}
                      value={String(value)}
                      onSelect={() => {
                        /* Another strange TypeScript solution */
                        props.onChange?.(props.enum[value as keyof T])
                        setOpen(false)
                      }}
                    >
                      <TbCheck
                        className={`w-4 h-4 mr-2 ${props.value === value ? 'opacity-100' : 'opacity-0'}`}
                      />
                      {value.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default EnumInput
