import { Checkbox } from '@/components/ui/checkbox'
import Copyable from '@/components/ui/copyable'
import DriverPreview from '@/components/ui/previews/DriverPreview'
import Table from '@/components/ui/Table/Table'
import { cn, formatAddress } from '@/lib/utils'
import { format } from 'date-fns'
import { FC, useContext } from 'react'
import LiveMapContext from './LiveMapContext'
import MapDriverIcon from './MapDriverIcon'

interface ILiveMapTableProps {
  className?: string
}

const LiveMapTable: FC<ILiveMapTableProps> = (props: ILiveMapTableProps) => {
  const context = useContext(LiveMapContext)

  const handleCheckboxChange = (value: boolean, id: number) => {
    context.setHiddenDrivers(
      value
        ? context.hiddenDrivers.filter(driverId => driverId !== id)
        : [...context.hiddenDrivers, id]
    )
  }

  return (
    <Table
      className={cn('w-full', props.className)}
      disablePagination
      columns={[
        {
          header: 'Driver',
          accessorFn: data => data.driver,
          cell: props => (
            <div className='flex flex-row place-items-center gap-2'>
              <MapDriverIcon driver={props.getValue()} />
              <DriverPreview value={props.getValue()} fullName />
              <Checkbox
                checked={!context.hiddenDrivers.includes(props.getValue().id)}
                onCheckedChange={checked =>
                  handleCheckboxChange(checked as boolean, props.getValue().id)
                }
              />
            </div>
          )
        },
        {
          header: 'Address',
          accessorFn: data => formatAddress(data.location),
          cell: props => (
            <Copyable value={props.getValue()} className='text-xs w-40'>
              {props.getValue()}
            </Copyable>
          )
        },
        {
          header: 'Updated At',
          accessorFn: data => data.timestamp,
          cell: props => (
            <span>{format(props.getValue(), 'MM.dd.yyyy HH:mm')}</span>
          )
        }
      ]}
      data={context.latestGeolocations}
    />
  )
}
export default LiveMapTable

