import { useAppSelector } from '@/hooks/useAppSelector'
import { cn, formatName } from '@/lib/utils'
import { AdvancedMarker, Map, RenderingType } from '@vis.gl/react-google-maps'
import { format } from 'date-fns'
import { FC, useContext, useMemo } from 'react'
import LiveMapContext from './LiveMapContext'
import MapDriverIcon from './MapDriverIcon'

interface ILiveMapProps {
  className?: string
}

const allowedMoveDistance = 8

const LiveMap: FC<ILiveMapProps> = (props: ILiveMapProps) => {
  const context = useContext(LiveMapContext)

  const mapType = useAppSelector(state => state.settingsState.mapType)
  const allLocations = useMemo(
    () =>
      context.latestGeolocations
        ? context.latestGeolocations.map(mapElement => mapElement.location)
        : [],
    [context.latestGeolocations]
  )

  const minLatitude = useMemo(
    () => Math.min(...allLocations.map(location => location.latitude)),
    [allLocations]
  )

  const maxLatitude = useMemo(
    () => Math.max(...allLocations.map(location => location.latitude)),
    [allLocations]
  )

  const minLongitude = useMemo(
    () => Math.min(...allLocations.map(location => location.longitude)),
    [allLocations]
  )

  const maxLongitude = useMemo(
    () => Math.max(...allLocations.map(location => location.longitude)),
    [allLocations]
  )

  const center = useMemo(
    () => ({
      lat: (minLatitude + maxLatitude) / 2,
      lng: (minLongitude + maxLongitude) / 2
    }),
    [minLatitude, maxLatitude, minLongitude, maxLongitude]
  )

  return (
    <Map
      className={cn('w-full h-full [&>*]:rounded-md', props.className)}
      mapId='dea1fd60813b4e75'
      defaultZoom={4}
      maxZoom={1000}
      defaultCenter={center}
      styles={[
        {
          featureType: 'all',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }]
        }
      ]}
      restriction={{
        latLngBounds: {
          north: maxLatitude + allowedMoveDistance,
          south: minLatitude - allowedMoveDistance,
          west: minLongitude - allowedMoveDistance,
          east: maxLongitude + allowedMoveDistance
        },
        strictBounds: true
      }}
      renderingType={RenderingType.VECTOR}
      mapTypeId={mapType}
      gestureHandling='greedy'
      disableDefaultUI={true}
      keyboardShortcuts={true}
    >
      {context.latestGeolocations.map(
        mapElement =>
          !context.hiddenDrivers.includes(mapElement.driver.id) && (
            <AdvancedMarker
              key={mapElement.driver.id}
              position={{
                lat: mapElement.location.latitude,
                lng: mapElement.location.longitude
              }}
            >
              <div className='flex flex-col gap-2 justify-center place-items-center w-20'>
                <MapDriverIcon driver={mapElement.driver} />
                <div className='flex flex-col justify-center place-items-center w-32 py-0.5 bg-background/80 rounded-sm backdrop-blur-sm text-sm text-center'>
                  <span>{formatName(mapElement.driver.user)}</span>
                  <span className='text-muted-foreground'>
                    {format(mapElement.timestamp, 'HH:mm')}
                  </span>
                </div>
              </div>
            </AdvancedMarker>
          )
      )}
    </Map>
  )
}
export default LiveMap

