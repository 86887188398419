import { toast } from '@/components/ui/use-toast'
import companyService from '@/services/company.service'
import { IAPIError } from '@/types/common.type'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useDeleteLogo = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      return await companyService.deleteLogo()
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['company']
      })
      await queryClient.invalidateQueries({
        queryKey: ['logo']
      })
    },
    onError: (error: IAPIError) => {
      toast({
        title: `Something went wrong. Code: ${error.statusCode}.`,
        description: error.message,
        variant: 'destructive'
      })
    }
  })
}

