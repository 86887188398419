import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import TextInput from '@/components/ui/inputs/TextInput'
import { Separator } from '@/components/ui/separator'
import Spinner from '@/components/ui/Spinner'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useCompany } from '@/hooks/queries/useCompany'
import { TabsContent } from '@radix-ui/react-tabs'
import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import CompanyLogo from './CompanyLogo'
import AutoschedulerTab from './tabs/autoschedulerTab/AutoschedulerTab'
import ImportTab from './tabs/importTab/ImportTab'

enum Tab {
  General = 'general',
  Autoscheduler = 'autoscheduler',
  Import = 'import'
}

const SettingsPage: FC = () => {
  const company = useCompany()

  const [searchParams, setSearchParams] = useSearchParams({
    tab: Tab.General
  })

  return (
    <div className='flex flex-col gap-4 w-full h-full'>
      {company.isLoading || !company.data ? (
        <Spinner />
      ) : (
        <Tabs
          defaultValue={searchParams.get('tab') || Tab.General}
          className='flex flex-col gap-4'
        >
          <TabsList className='flex flex-row w-full justify-start'>
            <TabsTrigger
              value={Tab.General}
              onClick={() => setSearchParams({ tab: Tab.General })}
            >
              General
            </TabsTrigger>
            <TabsTrigger
              value={Tab.Autoscheduler}
              onClick={() => setSearchParams({ tab: Tab.Autoscheduler })}
            >
              Autoscheduler
            </TabsTrigger>
            <TabsTrigger
              value={Tab.Import}
              onClick={() => setSearchParams({ tab: Tab.Import })}
            >
              Import
            </TabsTrigger>
          </TabsList>
          <TabsContent value={Tab.General}>
            <Card>
              <CardHeader>
                <CardTitle>General</CardTitle>
                <CardDescription>
                  Make changes to general settings here.
                </CardDescription>
                <Separator />
              </CardHeader>
              <CardContent className='flex flex-col gap-4'>
                <CompanyLogo company={company.data} />
                <TextInput
                  title='Company Name'
                  value={company.data.name}
                  disabled
                  className='max-w-80'
                />
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value={Tab.Autoscheduler}>
            <AutoschedulerTab company={company.data} />
          </TabsContent>
          <TabsContent value={Tab.Import}>
            <ImportTab company={company.data} />
          </TabsContent>
        </Tabs>
      )}
    </div>
  )
}

export default SettingsPage
