import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuRadioGroup,
  ContextMenuRadioItem,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger
} from '@/components/ui/context-menu'
import { useCalled } from '@/hooks/mutations/useCalled'
import { useSetScheduleStatus } from '@/hooks/mutations/usetSetScheduleStatus'
import { useUncombineTrip } from '@/hooks/mutations/useUncombineTrip'
import { ISchedule, ITrip, ScheduleStatus } from '@/types/trips.type'
import { FC, useState } from 'react'
import {
  TbAnchor,
  TbPencil,
  TbPhone,
  TbUnlink,
  TbUser,
  TbUserOff
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import ChangeStatusDialog from './ChangeStatusDialog'
import UnassignDialog from './UnassignDialog'

interface ITripContextMenuProps {
  current: ISchedule
  parent: ITrip
}

const ScheduleContextMenu: FC<ITripContextMenuProps> = (
  props: ITripContextMenuProps
) => {
  const navigate = useNavigate()

  const { mutate: setStatus } = useSetScheduleStatus()
  const { mutate: setCalled } = useCalled()
  const { mutate: uncombine } = useUncombineTrip()

  const [dialogStatus, setDialogStatus] = useState<ScheduleStatus>()
  const [unassigableTrip, setUnassignableTrip] = useState<ITrip>()

  const handleStatusChange = (status: ScheduleStatus) => {
    if (status === ScheduleStatus.Canceled || status === ScheduleStatus.NoShow)
      return setDialogStatus(status)

    return setStatus({
      schedule: props.current,
      status: status
    })
  }

  return (
    <>
      <ChangeStatusDialog
        schedule={props.current}
        changeableStatus={dialogStatus}
        setChangeableStatus={setDialogStatus}
      />
      <UnassignDialog
        unassignableTrip={unassigableTrip}
        setUnassignableTrip={setUnassignableTrip}
      />
      {props.parent ? (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem
            onClick={() => navigate(`/trips/${props.current.tripId}`)}
          >
            <TbPencil className='mr-2 h-4 w-4' />
            Edit Trip
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() =>
              navigate(`/drivers/${props.parent?.shift?.driverId}`)
            }
            disabled={!!!props.parent?.shift}
          >
            <TbUser className='mr-2 h-4 w-4' />
            Edit Driver
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() => navigate(`/riders/${props.current.riderId}`)}
          >
            <TbAnchor className='mr-2 h-4 w-4' />
            Edit Rider
          </ContextMenuItem>
          {props.parent.shift && (
            <ContextMenuItem onClick={() => setUnassignableTrip(props.parent)}>
              <TbUserOff className='mr-2 h-4 w-4' />
              Unassign
            </ContextMenuItem>
          )}
          {props.current.willCall && (
            <ContextMenuItem onClick={() => setCalled(props.current)}>
              <TbPhone className='mr-2 h-4 w-4' />
              Person Called
            </ContextMenuItem>
          )}
          <ContextMenuSeparator />
          <ContextMenuSub>
            <ContextMenuSubTrigger>Status</ContextMenuSubTrigger>
            <ContextMenuSubContent>
              <ContextMenuRadioGroup
                onValueChange={value =>
                  handleStatusChange(value as ScheduleStatus)
                }
                value={props.current.status}
              >
                {props.parent.shift ? (
                  <ContextMenuRadioItem value={ScheduleStatus.Assigned}>
                    Assigned
                  </ContextMenuRadioItem>
                ) : (
                  <ContextMenuRadioItem value={ScheduleStatus.Unassigned}>
                    Unassigned
                  </ContextMenuRadioItem>
                )}
                <ContextMenuRadioItem value={ScheduleStatus.NoShow}>
                  No Show
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.ArrivedAtDO}>
                  Arrived (DO)
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.ArrivedAtPU}>
                  Arrived (PU)
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.Canceled}>
                  Canceled
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.Completed}>
                  Completed
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.InRoute}>
                  In Route
                </ContextMenuRadioItem>
                <ContextMenuRadioItem value={ScheduleStatus.InTransit}>
                  In Transit
                </ContextMenuRadioItem>
              </ContextMenuRadioGroup>
            </ContextMenuSubContent>
          </ContextMenuSub>
        </ContextMenuContent>
      ) : (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem onClick={() => uncombine(props.current.id)}>
            <TbUnlink className='mr-2 h-4 w-4' />
            Uncombine
          </ContextMenuItem>
          {(props.current as unknown as ITrip).shift && (
            <ContextMenuItem
              onClick={() =>
                setUnassignableTrip(props.current as unknown as ITrip)
              }
            >
              <TbUserOff className='mr-2 h-4 w-4' />
              Unassign
            </ContextMenuItem>
          )}
        </ContextMenuContent>
      )}
    </>
  )
}
export default ScheduleContextMenu
