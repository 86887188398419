import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import FileInput from '@/components/ui/inputs/FileInput'
import Spinner from '@/components/ui/Spinner'
import { useLogo } from '@/hooks/queries/useLogo'
import { cn } from '@/lib/utils'
import { ICompany } from '@/types/company.type'
import { FC, useState } from 'react'
import { TbPencil, TbPhoto, TbTrash } from 'react-icons/tb'
import { useDeleteLogo } from './hooks/useDeleteLogo'
import { useUploadLogo } from './hooks/useUpdateLogo'

interface ICompanyLogoProps {
  company: ICompany
  className?: string
}

const CompanyLogo: FC<ICompanyLogoProps> = (props: ICompanyLogoProps) => {
  const [file, setFile] = useState<File>()

  const logo = useLogo(props.company.isLogoUploaded)
  const uploadLogo = useUploadLogo()
  const deleteLogo = useDeleteLogo()

  const submit = () => {
    if (!file) return

    uploadLogo.mutate(file)
  }

  return (
    <div className={cn('relative w-max h-max', props.className)}>
      {props.company.isLogoUploaded && logo.data ? (
        <img
          className='w-96 rounded-md'
          title={`${props.company.name}'s logo`}
          src={logo.data}
        />
      ) : (
        <div className='flex flex-col justify-center place-items-center w-32 h-32 rounded-full border border-dashed font-semibold text-4xl select-none'>
          {props.company.name[0].toUpperCase()}
        </div>
      )}
      <div className='flex flex-row gap-0.5 absolute -right-1 -top-1'>
        <Dialog>
          <DialogTrigger asChild>
            <Button
              variant='outline'
              size='icon'
              type='button'
              className='h-8 w-8 p-2 rounded-full'
              title='Update profile picture'
            >
              <TbPencil />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Upload company logo</DialogTitle>
              <DialogDescription>
                Select a new company logo below and click "Upload" to update
                your company logo.
              </DialogDescription>
            </DialogHeader>
            <FileInput
            accept='.png|.jpeg|.jpg'
              required
              onChange={event => {
                if (event.target.files?.[0]) setFile(event.target.files[0])
              }}
            />
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={!file || uploadLogo.isPending}
                  onClick={submit}
                  type='button'
                >
                  Upload
                  {uploadLogo.isPending ? (
                    <Spinner className='ml-2 h-4 w-4' />
                  ) : (
                    <TbPhoto className='ml-2 h-4 w-4' />
                  )}
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        {props.company.isLogoUploaded && (
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                variant='outline'
                size='icon'
                type='button'
                className='h-8 w-8 p-2 rounded-full'
                title='Update profile picture'
              >
                <TbTrash />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Delete company logo</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete
                  current company logo.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={() => deleteLogo.mutate()}>
                  Continue
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </div>
    </div>
  )
}

export default CompanyLogo

