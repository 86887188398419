import Copyable from '@/components/ui/copyable'
import { formatAddress } from '@/lib/utils'
import { CellContext } from '@tanstack/react-table'
import { FC } from 'react'
import { TbCircle } from 'react-icons/tb'

const AddressesCell: FC<CellContext<any, any>> = (
  props: CellContext<any, any>
) => {
  const formattedFrom = formatAddress(props.getValue().from, 'normal')
  const formattedTo = formatAddress(props.getValue().to, 'normal')

  return (
    props.getValue().from &&
    props.getValue().to && (
      <div className='flex flex-col gap-1 text-xs w-60 overflow-ellipsis'>
        <div className='flex flex-row place-items-center gap-2'>
          <TbCircle className='h-3 w-3' />
          <Copyable value={formattedFrom}>{formattedFrom}</Copyable>
        </div>
        <div className='flex flex-row place-items-center gap-2'>
          <TbCircle className='h-3 w-3' />
          <Copyable value={formattedTo}>{formattedTo}</Copyable>
        </div>
      </div>
    )
  )
}

export default AddressesCell
