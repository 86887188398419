import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '@/components/ui/form'
import { Separator } from '@/components/ui/separator'
import { ISchemaUser, IUser, userSchema } from '@/types/users.type'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TbBell, TbKey, TbNews } from 'react-icons/tb'
import TabHeader from '../../components/tab-header'
import { useUpdateMe } from '../../hooks/useUpdateMe'
import SwitchSetting from '../../components/switch-setting'

interface INotificationsTabProps {
  me: IUser
}

const NotificationsTab: FC<INotificationsTabProps> = (
  props: INotificationsTabProps
) => {
  const form = useForm<Pick<ISchemaUser, 'notificationsSettings'>>({
    resolver: yupResolver(userSchema.pick(['notificationsSettings']))
  })

  const { mutate: submit } = useUpdateMe()

  useEffect(() => form.reset(props.me), [props.me])

  return (
    <Form {...form}>
      <form
        className='flex flex-col gap-6'
        onSubmit={form.handleSubmit(data => submit(data as IUser))}
      >
        <TabHeader
          title='Notifications'
          description='Manage what type of notifications you want to receive.'
        />
        <Separator />
        <div className='flex flex-col gap-4 w-[600px]'>
          <FormField
            control={form.control}
            name='notificationsSettings.emailOnLogin'
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <SwitchSetting
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    icon={<TbKey />}
                    title='On-login emails'
                    description='Receive emails when somebody logs in from unusual location.'
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='notificationsSettings.news'
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <SwitchSetting
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    icon={<TbNews />}
                    title='News emails'
                    description='Find out the latest information about ManageMENT.'
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button className='w-max h-8' type='submit'>
          <TbBell className='h-4 w-4 mr-2' />
          Update Notifications
        </Button>
      </form>
    </Form>
  )
}
export default NotificationsTab
