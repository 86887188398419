import { Button } from '@/components/ui/button'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel
} from '@/components/ui/context-menu'
import DriverPreview from '@/components/ui/previews/DriverPreview'
import VehiclePreview from '@/components/ui/previews/VehiclePreview'
import Table, { ICustomizableTableProps } from '@/components/ui/Table/Table'
import { useAssignTrip } from '@/hooks/mutations/useAssignTrip'
import { isTripActiveOrComplete } from '@/lib/utils'
import { ITimeRange } from '@/types/common.type'
import { IShift } from '@/types/shifts.type'
import { ITrip } from '@/types/trips.type'
import { format } from 'date-fns'
import { FC, useContext } from 'react'
import { TbPencil, TbShirt } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import DispatchPageContext from '../DispatchPageContext'

const DispatchPageShiftsTable: FC<ICustomizableTableProps<IShift>> = (
  props: ICustomizableTableProps<IShift>
) => {
  const context = useContext(DispatchPageContext)

  const navigate = useNavigate()
  const { mutate: assign } = useAssignTrip()

  return (
    <Table
      {...props}
      disablePagination
      emptyElement={
        <div className='flex flex-col gap-2 place-items-center justify-center text-center'>
          <TbShirt className='h-8 w-8' />
          <h3 className='font-semibold text-lg'>No shifts found</h3>
          <span className='text-sm text-muted-foreground'>
            There are no shifts for {format(context.date, 'MM.dd.yyyy')}.<br />
            Select other day or create one below.
          </span>
          <Button className='h-8' onClick={() => navigate('/shifts/create')}>
            Create Shift
          </Button>
        </div>
      }
      dnd={{
        draggable: false,
        droppable: 'row',
        type: 'shift',
        canDrop: (item: ITrip) => {
          if (isTripActiveOrComplete(item)) return false

          return true
        },
        onDrop: (item: ITrip, original: IShift) => {
          assign({
            trip: item,
            shift: original
          })
        }
      }}
      contextMenu={(shift: IShift) => (
        <ContextMenuContent>
          <ContextMenuLabel>Actions</ContextMenuLabel>
          <ContextMenuItem
            onClick={() => navigate(`/drivers/${shift.driverId}`)}
          >
            Edit Driver
          </ContextMenuItem>
          <ContextMenuItem onClick={() => navigate(`/shifts/${shift.id}`)}>
            Edit Shift
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() => navigate(`/vehicles/${shift.vehicleId}`)}
          >
            Edit Vehicle
          </ContextMenuItem>
        </ContextMenuContent>
      )}
      columns={[
        {
          accessorKey: 'driver',
          header: 'Driver',
          accessorFn: data => data.driver,
          cell: props => <DriverPreview value={props.getValue()} />
        },
        {
          accessorKey: 'vehicle',
          header: 'Vehicle',
          accessorFn: data => data.vehicle,
          cell: props => (
            <VehiclePreview value={props.getValue()} className='text-xs' />
          )
        },
        {
          id: 'workingTime',
          accessorKey: 'workingTime',
          header: 'Working Time',
          cell: props => (
            <span className='whitespace-nowrap'>
              {format(props.getValue().start, 'HH:mm')} -{' '}
              {format(props.getValue().end, 'HH:mm')}
            </span>
          )
        },
        {
          id: 'breaks',
          header: 'Breaks',
          accessorKey: 'breaks',
          cell: props =>
            props.getValue<ITimeRange[]>().map((break_, index) => (
              <p key={index} className='whitespace-nowrap'>
                {format(break_.start, 'HH:mm')} - {format(break_.end, 'HH:mm')}
              </p>
            ))
        },
        {
          id: 'actions',
          size: 20,
          cell: props => (
            <Button
              onClick={() => navigate(`/shifts/${props.row.original.id}`)}
              size='icon'
              variant='ghost'
              className='h-8 w-8 p-0'
            >
              <TbPencil className='h-4 w-4' />
            </Button>
          )
        }
      ]}
    />
  )
}
export default DispatchPageShiftsTable
