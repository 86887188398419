import driverService from '@/services/drivers.service'
import { useQuery } from '@tanstack/react-query'

export const useDrivers = (refetchOnWindowFocus = true) =>
  useQuery({
    queryKey: ['drivers'],
    queryFn: () => {
      return driverService.getAll()
    },
    select: response => response.data,
    refetchOnWindowFocus: refetchOnWindowFocus
  })
