import { IAddress } from '@/types/address.type'
import {
  ITrip,
  ScheduleStatus,
  SettableScheduleStatus
} from '@/types/trips.type'
import { IVehicle } from '@/types/vehicles.type'
import { clsx, type ClassValue } from 'clsx'
import { set } from 'date-fns'
import { parse, ParseConfig, ParseResult } from 'papaparse'
import { twMerge } from 'tailwind-merge'

export type AddressFormat = 'normal' | 'short' | 'county'

export const setSameTimeDifferentDate = (source: Date, target: Date): Date => {
  return set(source, {
    year: target.getFullYear(),
    month: target.getMonth(),
    date: target.getDate()
  })
}

export const formatVehicle = (vehicle: IVehicle) => {
  if (!vehicle) return ''

  return `${vehicle.name} - ${vehicle.make} ${vehicle.model} (${vehicle.year})`
}

export const formatName = (
  names: {
    firstName: string
    middleName?: string
    lastName: string
  },
  middleName = true
) =>
  `${names.firstName} ${names.middleName && middleName ? `${names.middleName} ` : ''}${names.lastName}`

export const formatAddress = (address: IAddress, format?: AddressFormat) => {
  if (!address || (!address.address && !address.county)) return ''

  if (address.address?.includes(','))
    address.address = address.address.replaceAll(',', '')

  const fixAddress = (addressElements: (string | number)[]) =>
    addressElements.filter(
      element => element !== ' ' && element !== undefined && element !== 0
    )

  if (format === 'short')
    return fixAddress([
      address.address,
      address.city,
      address.state,
      address.county
    ]).join(', ')

  if (format === 'county')
    return fixAddress([address.county, address.state]).join(', ')

  return fixAddress([
    address.address,
    address.city,
    address.state,
    address.zipCode,
    address.county
  ]).join(', ')
}

export const isTripActive = (trip: ITrip) =>
  trip.schedules.some(schedule =>
    [
      ScheduleStatus.ArrivedAtDO,
      ScheduleStatus.ArrivedAtPU,
      ScheduleStatus.InRoute,
      ScheduleStatus.InTransit
    ].includes(schedule.status)
  )
  
export const isTripActiveOrComplete = (trip: ITrip) =>
  trip.schedules.some(schedule =>
    [
      ScheduleStatus.ArrivedAtDO,
      ScheduleStatus.ArrivedAtPU,
      ScheduleStatus.InRoute,
      ScheduleStatus.InTransit,
      ScheduleStatus.Completed
    ].includes(schedule.status)
  )

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}

export const formatScheduleStatus = (
  status: ScheduleStatus | SettableScheduleStatus
) => {
  const formattedStatuses: Record<ScheduleStatus, string> = {
    Unassigned: 'Unassigned',
    Assigned: 'Assigned',
    Completed: 'Completed',
    Canceled: 'Canceled',
    InRoute: 'In Route',
    InTransit: 'In Transit',
    ArrivedAtPU: 'Arrived (PU)',
    ArrivedAtDO: 'Arrived (DO)',
    NoShow: 'No Show'
  }

  return formattedStatuses[status] ?? String(status)
}

export const emailToMailService = (email: string) => {
  const associations: Record<
    string,
    {
      name: string
      url: string
    }
  > = {
    'gmail.com': {
      name: 'Gmail',
      url: 'https://mail.google.com/mail/u/0/#inbox'
    },
    'outlook.com': {
      name: 'Outlook',
      url: 'https://outlook.live.com/mail/0/'
    },
    'hotmail.com': {
      name: 'Hotmail',
      url: 'https://outlook.live.com/mail/0/'
    },
    'live.com': {
      name: 'Live',
      url: 'https://outlook.live.com/mail/0/'
    },
    'yahoo.com': {
      name: 'Yahoo',
      url: 'https://mail.yahoo.com/mail/u/0/#inbox'
    },
    'protonmail.com': {
      name: 'Protonmail',
      url: 'https://mail.protonmail.com/inbox'
    },
    'icloud.com': {
      name: 'iCloud',
      url: 'https://mail.icloud.com/mail/u/0/#inbox'
    },
    'aol.com': {
      name: 'AOL',
      url: 'https://mail.aol.com/mail/u/0/#inbox'
    }
  }

  const domain = email.split('@')[1]
  return associations[domain]
}

type ParseConfigOmitCompleteAndError<T> = Omit<T, 'complete' | 'error'>

export const parseAsync = <T>(
  data: string,
  config: ParseConfigOmitCompleteAndError<ParseConfig<T>>
): Promise<ParseResult<T>> =>
  new Promise((resolve, reject) => {
    parse<T>(data, { ...config, complete: resolve, error: reject })
  })

export const nameRegExp = /^[a-zA-Z ,.'-]+$/
